import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import * as stsReducer from "../reducers/sts.reducer";
import {
  appLogo,
  claimTypes,
  whiteLabelNames,
  userTypes,
  countries,
  claimValues,
  ELoginMethod,
} from "src/app/appsettings";
import { Config } from "src/app/services/config/config.service";
import { Claim } from "src/app/services/accounts/accounts.model";

const VALUE_TRUE = "true";
const VALUE_FALSE = "false";

const State = (state: AppState) => state[stsReducer.stsFeatureKey];

const getName = createSelector(State, (data) => (data.name ? data.name : ""));

const getAppLogo = createSelector(State, (data) => data.config ? appLogo.SYNLAB : appLogo.LOADING);

const isAppWhiteLabeled = createSelector(State, (data) => {
  return getConfig(data.config, claimTypes.WHITE_LABEL_ENABLED).toLowerCase() === VALUE_TRUE;
});

const getSynlabId = createSelector(State, (data) => data.synlabId);
const getCountry = createSelector(State, (data) => data.country);
const isSynlabAccessUser = createSelector(State, (data) =>
  data.role.some((role) => role === userTypes.SYNLAB_ACCESS_USER)
);
const isAnonymousUser = createSelector(
  State,
  (data) =>
    data.claims.find((c) => c.claimType == claimTypes.ANONYMOUS_USER)?.claimValue.toLocaleLowerCase() ==
    claimValues.TRUE
);

const isUkDfpAdminUser = createSelector(State, (data) => data.role.some((role) => role === userTypes.UK_DFP_ADMIN));
const isUkShfyAdminUser = createSelector(State, (data) => data.role.some((role) => role === userTypes.UK_SHFY_ADMIN));

const isGenePlanetElligibleUser = createSelector(
  State,
  (data) =>
    data.role.some((role) => role === userTypes.SYNLAB_ACCESS_USER) &&
    data &&
    data.claims &&
    data.claims.find((c) => c.claimType == claimTypes.ANONYMOUS_USER)?.claimValue.toLocaleLowerCase() !=
      claimValues.TRUE &&
    data.claims.find((c) => c.claimType == claimTypes.COUNTRY_STORAGE)?.claimValue.toLocaleLowerCase() ==
      countries.ESTONIA &&
    data.claims.find((c) => c.claimType == claimTypes.NATIONALITY)?.claimValue.toLocaleLowerCase() == countries.ESTONIA
);

const hasEmail = createSelector(
  State,
  (data) =>
    data.claims?.find((c) => c.claimType == claimTypes.EMAIL)?.claimValue != "" &&
    data.claims?.find((c) => c.claimType == claimTypes.NO_EMAIL)?.claimValue.toLowerCase() != claimValues.TRUE
);

const isWardProfile = createSelector(
  State,
  (data) =>
    data.claims?.find((c) => c.claimType == claimTypes.WARD_PROFILE)?.claimValue.toLowerCase() == claimValues.TRUE
);
const getBaseBookingUrl = createSelector(State, (s) => {
  return `${s.config?.BaseBookingUrl}/login/redirected`;
});
const getBasePlasmaUrl = createSelector(State, (s) => s.config.BasePlasmaUrl);
const getBookingAdminUrl = createSelector(State, (s) => s.config.BaseBookingAdminUrl);
const getWebreqAdminUrl = createSelector(State, (s) => s.config.BaseWebreqAdminUrl);
const getCheckResultUrl = createSelector(State, (s) => {
  const path =
    checkTenant(whiteLabelNames.UK_DFP, s.config) || checkTenant(whiteLabelNames.UK_SHFY, s.config)
      ? "/results/category"
      : "/results/check";
  return `${s.config.BasePlasmaUrl}${path}`;
});

const getBookingLinkEnabled = createSelector(State, isSynlabAccessUser, (data, isAppAccessUser) => {
  const hideBookingLinkOnThisRoles = [
    userTypes.SCHOOL_ADMIN,
    userTypes.SCHOOL_TEACHER,
    userTypes.LOLLI_SUPER_ADMIN,
    userTypes.LOLLI_LAB_USER,
    userTypes.BCP,
    userTypes.WEBREQ_USER,
  ];

  const isPTCountry = data.country && data.country == countries.PORTUGAL;
  const isEETenant = checkTenant(whiteLabelNames.ESTONIA, data.config);
  const isHUTenant = checkTenant(whiteLabelNames.HUNGARY, data.config);
  const isDfpTenant = checkTenant(whiteLabelNames.UK_DFP, data.config);
  const isShfytenant = checkTenant(whiteLabelNames.UK_SHFY, data.config);
  const hidden = data.role.some((n: any) => hideBookingLinkOnThisRoles.includes(n));

  return (
    (isPTCountry && !hidden && !isDfpTenant && !isShfytenant) ||
    ((isEETenant || isHUTenant) && isAppAccessUser)
  );
});

/**
 * @param str String.
 * @returns Return  equivaluent of string value in boolean.
 */
const strToBoolean = (str: string) => {
  const regex = /^\s*(true|1|on)\s*$/i;
  return regex.test(str);
};

/**
 * get ward limit from config. in case setup incorrectly, return 0
 */
const getWardLimit = createSelector(State, (data) => {
  return isNaN(+data.config.WardLimit) ? 0 : +data.config.WardLimit;
});

const isSchoolAdmin = createSelector(State, (data) => {
  return data.role.some((a) => a.indexOf(userTypes.SCHOOL_ADMIN) > -1);
});

const isMassCompanyUser = createSelector(
  State,
  (data) => data.role.includes(userTypes.MASS_COMPANY) && data.role.includes(userTypes.SYNLAB_ACCESS_USER)
);

const getVersion = createSelector(State, (data) => data.config.Version);

const getUserInfo = createSelector(State, (data) => data.userInfo);

const isLoggedIn = createSelector(State, (data) => data.loginStatus === true);

const getClaims = createSelector(State, (data) => data.claims);

const isMFARequired = createSelector(State, (data) => {
  // temporarily commented out this code
  // const isMfaRequired = getConfig(data.config, claimTypes.REQUIRE_MFA).toLowerCase() === VALUE_TRUE;

  const isMFAEnabled =
    data.claims?.find((n) => n.claimType == claimTypes.TWO_FACTOR_ENABLED)?.claimValue.toLowerCase() == VALUE_TRUE;
  const isTaraLogin = data.idp?.toLowerCase() == ELoginMethod.EE_TARA;
  const isTeliaLogin = data.idp?.toLowerCase() == ELoginMethod.FI_TELIA;
  const isBankIdLogin = data.idp?.toLowerCase() == ELoginMethod.CZ_BANKID;
  const requireMfa = !isMFAEnabled;

  if (isTaraLogin || isTeliaLogin || isBankIdLogin) {
    return false;
  }

  return requireMfa;
});

const showPreventionWellnessMenu = createSelector(State, (data) => {
  return data.claims
    ? data.claims.find((n) => n.claimType == claimTypes.COUNTRY_RESIDENCE).claimValue.toLowerCase() == countries.ESTONIA
    : false;
});
const getPreventionWellnessLink = createSelector(State, (s) => {
  return `${s.config.BasePlasmaUrl}/redirect?url=prevention-wellness`;
});

const getPetProfileEnabled = createSelector(State, (s) => {
  return getConfig(s.config, claimTypes.PET_PROFILE_ENABLED).toLowerCase() === VALUE_TRUE;
});

const getEnablePreventionWellness = createSelector(State, (data) => {
  return getConfig(data.config, claimTypes.ENABLE_PREVENTION_WELLNESS).toLowerCase() === VALUE_TRUE;
});

const isEstoniaInstance = createSelector(State, (data) => checkTenant(whiteLabelNames.ESTONIA, data?.config));
const isSpainInstance = createSelector(State, (data) => checkTenant(whiteLabelNames.SPAIN, data?.config));
const isHungaryInstance = createSelector(State, (data) => checkTenant(whiteLabelNames.HUNGARY, data?.config));
const isUkShfyInstance = createSelector(State, (data) => checkTenant(whiteLabelNames.UK_SHFY, data.config));
const isUkDfpInstance = createSelector(State, (data) => checkTenant(whiteLabelNames.UK_DFP, data.config));
const isPtInstance = createSelector(
  State,
  (data) => checkTenant(whiteLabelNames.PORTUGAL, data.config) || checkTenant(whiteLabelNames.INIT, data.config)
);
const isHUTenant = createSelector(State, (data) => checkTenant(whiteLabelNames.HUNGARY, data?.config));
const isESTenant = createSelector(State, (data) => checkTenant(whiteLabelNames.SPAIN, data?.config));
const isESTCTenant = createSelector(State, (data) => checkTenant(whiteLabelNames.SPAIN_TELECOUNSELING, data?.config));
const isFITenant = createSelector(State, (data) => checkTenant(whiteLabelNames.FINLAND, data?.config));
const isCzechRepublic = createSelector(State, (data) => checkTenant(whiteLabelNames.CZECH_REPUBLIC, data?.config));

const checkIfTaraLoginMethod = createSelector(State, (data) => {
  return data.idp?.toLowerCase() == ELoginMethod.EE_TARA;
});
const checkIfTeliaLoginMethod = createSelector(State, (data) => {
  return data.idp?.toLowerCase() == ELoginMethod.FI_TELIA;
});
const checkBankIdLoginMethod = createSelector(State, (data) => {
  return data.idp?.toLowerCase() == ELoginMethod.CZ_BANKID;
});


const getRoles = createSelector(State, (data) => data.role);
const getUserHeader = createSelector(State, (data) => ({
  givenName: data.claims?.find((c) => c.claimType == claimTypes.GIVEN_NAME)?.claimValue,
  familyName: data.claims?.find((c) => c.claimType == claimTypes.FAMILY_NAME)?.claimValue,
  synlabId: data.synlabId,
  claims: data.claims,
}));
const getGpEnrolleeInfo = createSelector(State, (data) => ({
  synlabId: data.synlabId,
  sex: data.claims?.find((c) => c.claimType == claimTypes.GENDER)?.claimValue,
  dateOfBirth: data.claims?.find((c) => c.claimType == claimTypes.DATE_OF_BIRTH)?.claimValue,
}));
const getWhiteLabelName = createSelector(State, (data) => data.config?.WhiteLabelName);

const getEnableOpenReplay = createSelector(State, (data) => {
  return getConfig(data.config, claimTypes.OPEN_REPLAY_ENABLE).toLowerCase() === VALUE_TRUE;
});
const getOpenReplayProjectKey = createSelector(State, (data) =>
  getConfig(data.config, claimTypes.OPEN_REPLAY_PROJECT_KEY)
);
const getOpenReplayIngestPoint = createSelector(State, (data) =>
  getConfig(data.config, claimTypes.OPEN_REPLAY_INGEST_API)
);
const getAppEnvironment = createSelector(State, (data) => getConfig(data.config, claimTypes.ENVIRONMENT));
const getUserUserId = createSelector(State, (data) => data.userInfo?.userId);
const getUserSub = createSelector(State, (data) => data.userInfo?.sub);
const getSSN = createSelector(State, (data) => data.userInfo?.ssn);

/**
 * Sts country selectors
 */
const getUserActiveEmail = createSelector(State, (data) => data.email);
const stsCountrySSNRegex = createSelector(State, (state) => state.countryInfo.data?.ssnRegex);
const isSTSCountryLoading = createSelector(State, (state) => state.countryInfo.status === "loading");

/**
 * Sts claim selectors
 */
const isEEWithNoEmail = createSelector(State, isEstoniaInstance, (state, isEE) => {
  const result = state?.claims?.find((claim) => claim.claimType === claimTypes.NO_EMAIL);
  return isEE && JSON.parse(result ? result.claimValue.toLocaleLowerCase() : claimValues.FALSE);
});

const getAccountsBaseUrl = createSelector(State, (state) => state.config?.BaseProfileUrl);

const hasGeneplanetOrders = createSelector(State, (state) => state.hasGeneplanetOrders);
const isGenePlanetEnrolled = createSelector(State, (state) => state.isGenePlanetEnrolled);

const isSuperSupportUser = createSelector(State, (data) => {
  const accessPerTenant = {
    [whiteLabelNames.ESTONIA]: [userTypes.EE_ADMIN],
    [whiteLabelNames.UK_DFP]: [userTypes.UK_DFP_ADMIN],
    [whiteLabelNames.UK_SHFY]: [userTypes.UK_SHFY_ADMIN],
  };
  const userTypesToCheck =
    data?.config.WhiteLabelName in accessPerTenant ? accessPerTenant[data.config.WhiteLabelName] : [userTypes.PT_ADMIN];
  return (
    data.role.some((role: string) => userTypesToCheck.includes(role)) &&
    isClaimTrue(data.claims, claimTypes.ALLOW_DELETE_USER)
  );
});

const getBaseProfileUrl = createSelector(State, (s) => {
  return `${s.config.BaseProfileUrl}`;
});

const useNewUI = createSelector(State, (data) => {
  const key: keyof Config = "UseNewUI";
  return getConfig(data.config, key).toLowerCase() === VALUE_TRUE;
});

const useNewNav = createSelector(
  useNewUI,
  isEstoniaInstance,
  (useNewUiResult, isEstoniaInstanceResult) => useNewUiResult || isEstoniaInstanceResult
);

const wardProfileAddEnabled = createSelector(
  State,
  (s) => s.config.WardProfileAddEnabled?.toLowerCase() === claimValues.TRUE
);
const wardProfileEditEnabled = createSelector(
  State,
  (s) => s.config.WardProfileEditEnabled?.toLowerCase() === claimValues.TRUE
);
const wardProfileDeleteEnabled = createSelector(
  State,
  (s) => s.config.WardProfileDeleteEnabled?.toLowerCase() === claimValues.TRUE
);

const getContactEmail = createSelector(
  State,
  (data) => data.claims.find((c) => c.claimType == claimTypes.CONTACT_EMAIL)?.claimValue
);

export const StsSelectors = {
  checkBankIdLoginMethod,
  getContactEmail,
  checkIfTeliaLoginMethod,
  wardProfileAddEnabled,
  wardProfileEditEnabled,
  wardProfileDeleteEnabled,
  getSSN,
  isCzechRepublic,
  isESTenant,
  isESTCTenant,
  isFITenant,
  useNewUI,
  isHUTenant,
  useNewNav,
  getUserActiveEmail,
  getAppEnvironment,
  getUserUserId,
  getUserSub,
  getOpenReplayIngestPoint,
  getOpenReplayProjectKey,
  getEnableOpenReplay,
  isEstoniaInstance,
  isSpainInstance,
  isHungaryInstance,
  getEnablePreventionWellness,
  getPetProfileEnabled,
  showPreventionWellnessMenu,
  getPreventionWellnessLink,
  isMFARequired,
  getClaims,
  getBookingLinkEnabled,
  getBaseBookingUrl,
  getBasePlasmaUrl,
  getBookingAdminUrl,
  getWebreqAdminUrl,
  getSynlabId,
  getName,
  isAppWhiteLabeled,
  getAppLogo,
  getCountry,
  isSynlabAccessUser,
  getWardLimit,
  getVersion,
  isSchoolAdmin,
  isMassCompanyUser,
  isWardProfile,
  getUserInfo,
  isLoggedIn,
  checkIfTaraLoginMethod,
  getRoles,
  getUserHeader,
  getGpEnrolleeInfo,
  isGenePlanetElligibleUser,
  getWhiteLabelName,
  isSTSCountryLoading,
  stsCountrySSNRegex,
  hasEmail,
  isEEWithNoEmail,
  isUkDfpAdminUser,
  isUkShfyAdminUser,
  getCheckResultUrl,
  getAccountsBaseUrl,
  hasGeneplanetOrders,
  isGenePlanetEnrolled,
  isUkShfyInstance,
  isUkDfpInstance,
  isPtInstance,
  isSuperSupportUser,
  getBaseProfileUrl,
  isAnonymousUser,
};

function getConfig(config: { [keys: string]: any }, keyName: string) {
  if (!config) {
    return "";
  }
  return config[keyName];
}

export function checkTenant(tenantName: string, config: Config): boolean {
  const isWhiteLabeled = getConfig(config, claimTypes.WHITE_LABEL_ENABLED).toLowerCase() === VALUE_TRUE;
  const whiteLabelName = getConfig(config, claimTypes.WHITE_LABEL_NAME).toLowerCase();

  switch (tenantName) {
    case whiteLabelNames.FINLAND:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.FINLAND;
    case whiteLabelNames.HUNGARY:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.HUNGARY;
    case whiteLabelNames.ESTONIA:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.ESTONIA;
    case whiteLabelNames.UK_DFP:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.UK_DFP;
    case whiteLabelNames.UK_SHFY:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.UK_SHFY;
    case whiteLabelNames.PORTUGAL:
      return !isWhiteLabeled && whiteLabelName == whiteLabelNames.PORTUGAL;
    case whiteLabelNames.INIT:
      return !isWhiteLabeled && whiteLabelName == whiteLabelNames.INIT;
    case whiteLabelNames.SPAIN:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.SPAIN;
    case whiteLabelNames.SPAIN_TELECOUNSELING:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.SPAIN_TELECOUNSELING;
    case whiteLabelNames.CZECH_REPUBLIC:
      return isWhiteLabeled && whiteLabelName == whiteLabelNames.CZECH_REPUBLIC;
    default:
      return false;
  }
}
function isClaimTrue(profile: any[], claim: string): boolean {
  if (!Array.isArray(profile)) return false;
  return profile.find((x: Claim) => x.claimType === claim)?.claimValue?.toLocaleLowerCase() == claimValues.TRUE;
}
