import { createFeatureSelector, createSelector } from "@ngrx/store";
import { claimValues } from "src/app/appsettings";
import { AppState } from "../reducers";
import { dkMfaResetFeatureKey } from "./dk-mfa-reset.reducer";

const State = (state: AppState) => state[dkMfaResetFeatureKey];

const getLoadingComponent = createSelector(State, (state) => {
  if (state.activityInProgress) {
    return state.activityName;
  }
  return "";
});
const getPhone = createSelector(State, ({ mfaResetClaims }) => {
  if (mfaResetClaims) {
    return mfaResetClaims.phone;
  }
  return null;
});
const getPhoneVerified = createSelector(State, ({ mfaResetClaims }) => {
  if (mfaResetClaims) {
    return mfaResetClaims.phone_verified.toLowerCase() == claimValues.TRUE;
  }
  return false;
});
const getCountry = createSelector(State, ({ mfaResetClaims }) => {
  if (mfaResetClaims) {
    return mfaResetClaims.country;
  }
  return false;
});
const getQueryParams = createSelector(State, ({ id, seqId }) => {
  return {
    id,
    seqId,
  };
});

export const DKMFAResetSelector = {
  getCountry,
  getPhone,
  getPhoneVerified,
  getLoadingComponent,
  getQueryParams,
};
