<div data-test-id="register-mfa-sms-component-wrapper"  class="component-wrapper pt-3" *transloco="let t1; read: tkey1">
  <div data-test-id="register-mfa-sms-wrapper"  class="d-flex">
    <div data-test-id="register-mfa-sms-header-wrapper" >
      <h3 data-test-id="register-mfa-sms-header" >
        {{ t1("smsTitle") }} <span data-test-id="register-mfa-sms-header-phone"  class="phone">({{ phoneNumber$ | async }})</span>
      </h3>
      <p data-test-id="register-mfa-sms-header-explanation-text">{{ t1("sms2FAExplanationText") }}</p>
    </div>
    <div data-test-id="register-mfa-sms-actions"  class="pl-5 tw-whitespace-nowrap actions">
      <div data-test-id="register-mfa-sms-toggle-sms-edit-btn-wrapper"  *ngIf="(editPhoneToggle$ | async) != true && (smsCodeEnableMFAToggle$ | async) != true; else showCancel">
        <button data-test-id="register-mfa-sms-toggle-sms-edit-btn"  class="btn btn btn-link mr-4 p-0" (click)="toggleEditSmsOnclick(true)">
          {{ t1("editLabel") | uppercase }}
        </button>
        <button data-test-id="register-mfa-sms-enable-mfa-btn"  class="btn btn btn-link p-0 btn-enable" (click)="enableMFA('phone')" [disabled]="enableSMSLoading">
          <span data-test-id="register-mfa-sms-enable-mfa-btn-content"  class="m-0 p-0 d-flex justify-content-center align-items-center">
            <span data-test-id="register-mfa-sms-enable-mfa-btn-loading"  *ngIf="enableSMSLoading; else showEnableText">
              <mat-spinner data-test-id="register-mfa-sms-enable-mfa-btn-loading-spinner"  diameter="24" strokeWidth="4"></mat-spinner>
            </span>
            <ng-template #showEnableText>{{ t1("enableButton") | uppercase }} </ng-template>
          </span>
        </button>
      </div>
      <ng-template #showCancel>
        <div data-test-id="register-mfa-sms-btn-cancel-wrapper"  class="btn-cancel tw-flex tw-flex-row-reverse">
          <button data-test-id="register-mfa-sms-reset-view-btn"  class="btn btn btn-link p-0" (click)="resetView()">{{ t1("cancelButton") | uppercase }}</button>
        </div>
      </ng-template>
    </div>
  </div>

  <div data-test-id="register-mfa-sms-sms-code-enable-mfa"  class="sms-code-enable-mfa">
    <!-- Enter sms code to enable sms 2fa -->
    <div data-test-id="register-mfa-sms-sms-code-enable-mfa-toggle" 
      class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5"
      *ngIf="(smsCodeEnableMFAToggle$ | async) == true; else showUpdatePhoneContainer"
    >
      <h5 data-test-id="register-mfa-sms-code-label1" >{{ t1("codeLabel1") | uppercase }}</h5>
      <div data-test-id="register-mfa-sms-code-submit-wrapper"  class="d-flex code-submit">
        <input data-test-id="register-mfa-sms-form-control"  type="text" class="form-control" [formControl]="smsCodeEnableMfaFC" id="smsCodeEnableMfaFC" />
        <div data-test-id="register-mfa-sms-submit-wrapper"  class="submit ml-4">
          <button data-test-id="register-mfa-sms-enable-mfa-verify-btn" 
            [disabled]="smsCodeEnableMfaFC.invalid || (getSMSMFACodeLoading$ | async) == true"
            (click)="enableMFAVerify('Phone')"
            class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
          >
            <span data-test-id="register-mfa-sms-enable-mfa-verify-loading-wrapper"  class="loading" *ngIf="(getSMSMFACodeLoading$ | async) == true; else showText1">
              <mat-spinner data-test-id="register-mfa-sms-enable-mfa-verify-loading-spinner"  diameter="24" strokeWidth="4"></mat-spinner>
            </span>
            <ng-template #showText1>
              <img data-test-id="register-mfa-sms-arrow-right"  src="assets/images/registerv2/arrow-right.png" alt="Icon" />
              <span data-test-id="register-mfa-sms-verify-btn-text"  class="pl-1">{{ t1("verifyButton") | uppercase }}</span>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template  #showUpdatePhoneContainer>
    <div data-test-id="register-mfa-sms-update-phone-container"  class="update-phone-container">
      <!-- Enter new mobile number -->
      <div data-test-id="register-mfa-sms-edit-phone-toggle-wrapper" 
        class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5"
        *ngIf="(editPhoneToggle$ | async) == true && (smsCodePhoneUpdateToggle$ | async) == false"
      >
        <h5 data-test-id="register-mfa-sms-mobile-number-label" >{{ t1("mobileNumberLabel") }}</h5>
        <div data-test-id="register-mfa-sms-form-group"  class="d-flex" [formGroup]="form">
          <app-mobile-number-picker data-test-id="register-mfa-sms-mobile-number-picker" 
            [name]="'phone'"
            (fgEvent)="validatePhoneFormGroupValues($event)"
            [countryControl]="form.get('country_phone')"
            [numberControl]="form.get('phone')"
            [selectedCountryISO]="form.get('country_phone')?.getRawValue()"
          ></app-mobile-number-picker>

          <div data-test-id="register-mfa-sms-submit-wrapper"  class="submit ml-4">
            <button data-test-id="register-mfa-sms-update-phone-number-btn" 
              class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
              [disabled]="!isValid || (editPhoneLoading$ | async) == true"
              (click)="updatePhoneNumber()"
            >
              <span data-test-id="register-mfa-sms-update-phone-number-btn-loading-wrapper"  class="loading" *ngIf="(editPhoneLoading$ | async) == true; else showText2">
                <mat-spinner data-test-id="register-mfa-sms-update-phone-number-btn-loading-spinner"  diameter="24" strokeWidth="4"></mat-spinner>
              </span>
              <ng-template #showText2>
                <img data-test-id="register-mfa-sms-arrow-right"  src="assets/images/registerv2/arrow-right.png" alt="Icon" />
                <span data-test-id="register-mfa-sms-sms-2fa-instruction-text"  class="pl-1">{{ t1("sms2FAInstructionText.text2") | uppercase }}</span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>

      <!-- Enter sms code to confirm new mobile number -->
      <div data-test-id="register-mfa-sms-phone-number-wrapper"  class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5" *ngIf="(smsCodePhoneUpdateToggle$ | async) == true">
        <h5 data-test-id="register-mfa-sms-code-label1" >{{ t1("codeLabel1") | uppercase }}</h5>
        <div data-test-id="register-mfa-sms-code-submit-wrapper"  class="d-flex code-submit">
          <input data-test-id="register-mfa-sms-form-control"  type="text" class="form-control" [formControl]="smsCodeFC" id="smsCodeFC" />
          <div data-test-id="register-mfa-sms-actions"  class="submit ml-4">
            <button data-test-id="register-mfa-sms-verify-phone-btn" 
              [disabled]="smsCodeFC.invalid || (editPhoneLoading$ | async) == true"
              (click)="verifyPhone()"
              class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
            >
              <span data-test-id="register-mfa-sms-verfify-btn-loading-wrapper"  class="loading" *ngIf="(editPhoneLoading$ | async) == true; else showText3">
                <mat-spinner data-test-id="register-mfa-sms-verify-btn-spinner"  diameter="24" strokeWidth="4"></mat-spinner>
              </span>
              <ng-template  #showText3>
                <img data-test-id="register-mfa-sms-arrow-right"  src="assets/images/registerv2/arrow-right.png" alt="Icon" />
                <span data-test-id="register-mfa-sms-verify-btn-text"  class="pl-1">{{ t1("verifyButton") }}</span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
