import { createAction, props } from "@ngrx/store";

const loadAppLanguage = createAction("[App] Load App Language", props<{ language: string }>());
const loadAppLanguageSuccess = createAction("[App] Load App Language Success", props<{ language: string }>());
const loadAppLanguageFailure = createAction(
  "[App] Load App Language Failure",
  props<{ language: string; error: string }>()
);
const setActiveLanguage = createAction("[App] Set Active Language", props<{ language: string }>());
const runCheckChangeEmailRequest = createAction("[App] run check change email request");

export const AppActions = {
  loadAppLanguage,
  loadAppLanguageSuccess,
  loadAppLanguageFailure,
  setActiveLanguage,
  runCheckChangeEmailRequest,
};
