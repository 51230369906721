export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
export const alphanumericUnicode = /^[\p{L}\s'’.-]+$/u;
export const fiAlphanumericUnicode = /^[^'"();&|!*%#@-]+$/u;
export const alphanumeric = /^[a-zA-Z\d _-]*$/;
export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const passwordValidation =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{8,}$/;

export const fiveDigitsPattern = /^[\d]{5}$/;
