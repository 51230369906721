import { Injectable } from '@angular/core';
import { AccountsService } from './accounts.service';
import { LoggerService } from '../logger.service';
import { BehaviorSubject, delay, filter, of, take } from 'rxjs';
import { Account } from './accounts.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/@store/reducers';
import { STSActions } from 'src/app/@store/actions/sts.actions';
import { claimTypes } from 'src/app/appsettings';
import { ConfigService } from '../config/config.service';
import { isInAvailableLocale } from 'src/app/transloco-root.module';
import { TranslocoService } from '@ngneat/transloco';
import { formatLocale } from 'src/app/plasma-ui-common/utils/format-locale';
import { LocalStorageKeys } from 'src/app/models/storage.model';
import { getLangCodeFromLocalStorage, getLangCodeFromQueryString } from 'src/app/utils/common.utils';

@Injectable({
  providedIn: 'root'
})
export class UserClaimsService {

  private userClaimsIsLoaded: boolean = false;
  public claims$ = new BehaviorSubject(null);

  constructor(
    private readonly accountsService: AccountsService,
    private readonly logger: LoggerService,
    private readonly store: Store<AppState>,
    private readonly config: ConfigService,
    private readonly transloco: TranslocoService
  ) { }

  public async getUserClaims(userId: string){
    try {
      if (!this.userClaimsIsLoaded) {
        await of(true).pipe(delay(1500)).toPromise();
        const rawClaims = await this.accountsService.get(userId).toPromise();

        const claims = this.getUserClaimsAsObject(rawClaims);
        this.userClaimsIsLoaded = true;
        this.claims$.next(claims);
      }
    } catch (error) {
      this.logger.log("getUserClaims error", error);
    }
  }

  private getUserClaimsAsObject(claims: Account): Record<string, any> {
    this.store.dispatch(STSActions.loadProfileSuccess({ claims }));
    const usertypes = claims
      .filter((claim) => claim.claimType == claimTypes.USER_TYPE)
      .map((usertype) => usertype.claimValue);

    return {
      ...claims.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {}),
      user_type: usertypes,
    };
  }

  public async setAppLanguageFromJwtToken(lang: string) {
    await this.config.envConfig$
      .pipe(
        filter((config) => Boolean(config)),
        take(1)
      )
      .toPromise();

    const getLang = isInAvailableLocale;
    let availableLanguages = [this.transloco.getDefaultLang(), ...this.getAvailableLanguages()];

    const fromStorage = getLangCodeFromLocalStorage();
    const fromQString: string = getLangCodeFromQueryString();
    const fromJwtLangCode: boolean = lang && getLang(lang, availableLanguages) != "";

    if (!fromQString && !fromStorage && fromJwtLangCode) {
      const langToSet = formatLocale(getLang(lang, availableLanguages));
      sessionStorage.setItem(LocalStorageKeys.language, langToSet);
      this.transloco.setActiveLang(langToSet);
    }
  }

  private getAvailableLanguages(): string[] {
    return this.config._envConfig?.AvailableLanguage?.split(",") || [];
  }
}
