import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeleteAccountComponent } from "./delete-account.component";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [DeleteAccountComponent],
  imports: [CommonModule, SharedModule, MatProgressSpinnerModule],
})
export class DeleteAccountModule {}
