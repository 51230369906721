import { createSelector } from "@ngrx/store";
import { initialMultiFactorAuthCountries } from "src/app/services/config/config.service";
import { AppState } from "../reducers";
import * as profileReducer from "../reducers/profile.reducer";
const State = (state: AppState) => state[profileReducer.profileFeatureKey];

const getProfile = createSelector(State, (data) => (data ? data : null));

const getAuthenticatorUri = createSelector(State, (data) => (data.authenticator_uri ? data.authenticator_uri : null));

const getRecoveryCodes = createSelector(State, (data) => (data.code ? data.code : null));

const getCurrentPhoneDetails = createSelector(State, (data) => (data.phone ? data.phone : null));

const getTwoFactorEnabled = createSelector(State, (data) => (data.two_factor_enabled ? data.two_factor_enabled : null));

const isTeacher = createSelector(State, (data) =>
  Array.isArray(data.profile_data.user_type)
    ? data.profile_data.user_type.some((n) => n === "school_teacher")
    : data.profile_data.user_type === "school_teacher"
);

const getTempPhoneDetails = createSelector(State, (data) => ({
  number: data.phone_temp.number,
  country_phone: data.phone_temp.country_phone,
}));

const getUserCountryRequireMFA = createSelector(
  State,
  (data) =>
    initialMultiFactorAuthCountries.indexOf(
      typeof data.residenceCountryCode === "string" ? data.residenceCountryCode.toLowerCase() : ""
    ) > -1
);

const getLocale = createSelector(State, (data) => (data.profile && data.profile.locale ? data.profile.locale : ""));
const getConsent = createSelector(State, (data) => data.consents);
const isMarketingConsentChecked = createSelector(State, (data) => data.isMarketingConsentChecked);
const getConsentsLoading = createSelector(State, (data) => data.consentLoading);
const getConsentsPayload = createSelector(State, (data) => data.consents_payload);
const hasInvalidFormFields = createSelector(State, (data) => data.invalidProfileFormFields?.length);

export const ProfileSelectors = {
  hasInvalidFormFields,
  isMarketingConsentChecked,
  getProfile,
  getAuthenticatorUri,
  getRecoveryCodes,
  getCurrentPhoneDetails,
  getTwoFactorEnabled,
  getTempPhoneDetails,
  getUserCountryRequireMFA,
  isTeacher,
  getLocale,
  getConsent,
  getConsentsLoading,
  getConsentsPayload,
};
