import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { TaraUserRegisterGetResponse, TaraUserRegisterUpdateResponse } from "src/app/models/tara-user.model";
import { ConsentsPayload } from "src/app/pages/index/pages/notification-settings/notification.model";
import { Claim } from "src/app/services/accounts/accounts.model";
import { ConfigService } from "src/app/services/config/config.service";
import { ApiService } from "../api/api.service";

@Injectable({ providedIn: "root" })
export class TaraRegisterProfileApiService {
  private readonly taraRegisterGetEndpoint = "/manage/accounts/tara-register-user";
  private readonly taraRegisterUpdateEndpoint = "/manage/accounts/tara-create-user";

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private api: ApiService
  ) {}
  getRegisterProfile(regId: string): Observable<TaraUserRegisterGetResponse> {
    return this.http.get<TaraUserRegisterGetResponse>(
      `${this.config.baseAuthUrl}${this.taraRegisterGetEndpoint}/${regId}`
    );
  }

  submitRegisterProfile(
    taraUniqueId: string,
    claims: Claim[],
    hasEmail?: boolean,
    userId?: string
  ): Observable<TaraUserRegisterUpdateResponse> {
    if (hasEmail) {
      return this.api.put<TaraUserRegisterUpdateResponse>(
        `${this.taraRegisterUpdateEndpoint}?taraUniqueId=${taraUniqueId}&userId=${userId}`,
        claims
      );
    }
    return this.api.post<TaraUserRegisterUpdateResponse>(`${this.taraRegisterUpdateEndpoint}/${taraUniqueId}`, claims);
  }

  updateConsents(consents: ConsentsPayload[], userId: string) {
    return this.api.put(`/api/consent/tarauser/${userId}`, consents);
  }
}
