import { Injectable } from "@angular/core";
import { reducers } from "../@store/reducers";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  getReducers() {
    return reducers;
  }
}
