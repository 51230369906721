import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import * as HeaderReducer from "../reducers/header.reducer";

const State = (state: AppState) => state[HeaderReducer.headerFeatureKey];

const getVisibility = createSelector(State, (state) => state.visible);

export const HeaderSelectors = {
  getVisibility,
};
