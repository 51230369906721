import { Action, createReducer, on } from "@ngrx/store";
import { E2FAProvider, EResetMFAActivity, IClaimObject } from "src/app/view/dk-mfa-reset/enum-dk-mfa";
import { DkMfaResetAction } from "./dk-mfa-reset.actions";

export const dkMfaResetFeatureKey = "dkMfaReset";

export interface State {
  id: string;
  seqId: string;
  isMFAResetLinkValid: boolean;
  mfaResetClaims: IClaimObject;
  activityInProgress: boolean;
  activityName: EResetMFAActivity;
}

export const initialState: State = {
  id: null,
  seqId: null,
  isMFAResetLinkValid: null,
  mfaResetClaims: null,
  activityInProgress: false,
  activityName: null,
};

export const reducer = createReducer(
  initialState,
  on(DkMfaResetAction.validateMFAResetLink, (state, { payload }) => ({
    ...state,
    activityInProgress: true,
    activityName: EResetMFAActivity.RESET_ISVALID,
    id: payload.id,
    seqId: payload.seqId,
  })),
  on(
    DkMfaResetAction.validateMFAResetLinkSuccess,
    DkMfaResetAction.validateMFAResetLinkFailed,
    DkMfaResetAction.changePhoneFailed,
    DkMfaResetAction.changePhoneSuccess,
    DkMfaResetAction.verifyChangePhoneSuccess,
    DkMfaResetAction.verifyChangePhoneFailed,
    DkMfaResetAction.set2FAFailed,
    DkMfaResetAction.set2FASuccess,
    DkMfaResetAction.verifySet2FAFailed,
    DkMfaResetAction.verifySet2FASuccess,
    (state) => ({
      ...state,
      activityInProgress: false,
      activityName: null,
    })
  ),
  on(DkMfaResetAction.getMFAResetClaims, (state) => ({
    ...state,
    activityInProgress: true,
    activityName: EResetMFAActivity.RESET_CLAIMS,
  })),
  on(DkMfaResetAction.getMFAResetClaimsSuccess, (state, { claims }) => ({
    ...state,
    activityInProgress: false,
    activityName: null,
    mfaResetClaims: claims,
  })),
  on(DkMfaResetAction.changePhone, (state) => ({
    ...state,
    activityInProgress: true,
    activityName: EResetMFAActivity.CHANGE_PHONE,
  })),
  on(DkMfaResetAction.verifyChangePhone, (state) => ({
    ...state,
    activityInProgress: true,
    activityName: EResetMFAActivity.VERIFY_PHONE,
  })),
  on(DkMfaResetAction.set2FA, (state, { body }) => ({
    ...state,
    activityInProgress: true,
    activityName:
      body.twoFactorProvider == E2FAProvider.SMS ? EResetMFAActivity.SET_2FA_SMS : EResetMFAActivity.SET_2FA_AUTH_APP,
  })),
  on(DkMfaResetAction.verifySet2FA, (state) => ({
    ...state,
    activityInProgress: true,
    activityName: EResetMFAActivity.VERIFY_2FA,
  }))
);
