import { Injectable } from '@angular/core';

export interface UserInfo {
  name: string;
  username?: string;
  country: string;
  client_id: string;
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  phone: string;
  preferred_username: string;
  sub: string;
  user_type: string | string[];
  userId?: string;
  ssn?: string;
}

export enum UserType {
  SuperAdmin = "superadmin",
  ApiAdmin = "apiadmin",
  ClientAdmin = "clientadmin",
  IdentityResourceAdmin = "identityresourceadmin",
  Bcp = "bcp",
  UefaAdmin = "uefa_admin",
  UefaLaboratory = "uefa_laboratory",
  UefaTeam = "uefa_team",
  UefaOfficial = "uefa_official",
  UefaUser = "uefa_user",
  UefaMlo = "uefa_mlo",
  UefaManagement = "uefa_management",
  UefaTeammloLab = "uefa_teammlo_lab",
  UefaMloLab = "uefa_mlo_lab",
  Doctor = "doctor",
  WebreqUser = "webreq_user",
  SynlabAccessUser = "synlab_access_user",
  WebreqAdmin = "webreq_admin",
  WebreqSuperAdmin = "webreq_superadmin",
  Patient = "patient",
  CustomerCare = "customer_care",
  DeAdmin = "de_admin",
  EsAdmin = "es_admin",
  ItAdmin = "it_admin",
  CoAdmin = "co_admin",
  DkAdmin = "dk_admin",
  PtAdmin = "pt_admin",
  EeAdmin = "ee_admin",
  AirAdmin = "air_admin",
  UkDfpAdmin = "uk_dfp_admin",
  UkShfyAdmin = "uk_shfy_admin",
  UserAdmin = "useradmin",
  SchoolAdmin = "school_admin",
  MassCompany = "mass_company",
  SchoolTeacher = "school_teacher",
  LolliSuperAdmin = "lolli_superadmin",
}

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  private currentUser: Record<string, any>;

  public readonly patient_users = [UserType.Patient];
  public readonly customer_care = [UserType.CustomerCare];
  public readonly de_admin = [UserType.DeAdmin];
  public readonly es_admin = [UserType.EsAdmin];
  public readonly it_admin = [UserType.ItAdmin];
  public readonly co_admin = [UserType.CoAdmin];
  public readonly dk_admin = [UserType.DkAdmin];
  public readonly pt_admin = [UserType.PtAdmin];
  public readonly ee_admin = [UserType.EeAdmin];
  public readonly air_admin = [UserType.AirAdmin];
  public readonly uk_dfp_admin = [UserType.UkDfpAdmin];
  public readonly uk_shfy_admin = [UserType.UkShfyAdmin];
  public readonly useradmin = [UserType.UserAdmin];
  public readonly synlab_access_users = [UserType.SynlabAccessUser];
  public readonly bcp_user = [UserType.Bcp];
  public readonly school_admin = UserType.SchoolAdmin;
  public readonly mass_company = UserType.MassCompany;
  public readonly school_teacher = UserType.SchoolTeacher;
  public readonly lolli_superadmin = UserType.LolliSuperAdmin;
  public readonly webreq_users = [UserType.Bcp, UserType.Doctor, UserType.WebreqUser];
  public readonly webreqadmin_users = [UserType.WebreqAdmin, UserType.WebreqSuperAdmin];
  public readonly admintypes = [
    UserType.SuperAdmin,
    UserType.ApiAdmin,
    UserType.ClientAdmin,
    UserType.IdentityResourceAdmin,
    UserType.Bcp,
  ];
  public readonly uefa_users = [
    UserType.UefaAdmin,
    UserType.UefaLaboratory,
    UserType.UefaTeam,
    UserType.UefaOfficial,
    UserType.UefaUser,
    UserType.UefaMlo,
    UserType.UefaManagement,
    UserType.UefaTeammloLab,
    UserType.UefaMloLab,
  ];
  public readonly admin_users = [
    UserType.SuperAdmin,
    UserType.ClientAdmin,
    UserType.ApiAdmin,
    UserType.IdentityResourceAdmin,
  ];

  constructor() {}

  public setUser(user: Record<string, any>){
    this.currentUser = user;
  }

  private checkUserAccess(types: UserType[]): boolean {
    const userTypes = this.currentUser?.user_type ?? [];
    return userTypes.some((type) => types.includes(type as UserType));
  }

  isAdminUser(): boolean {
    return this.checkUserAccess(this.admin_users);
  }
  isAppUser(): boolean {
    return this.checkUserAccess(this.synlab_access_users);
  }
  isWebreqAdminUser(): boolean {
    return this.checkUserAccess(this.webreqadmin_users);
  }
  isWebreqUser(): boolean {
    return this.checkUserAccess(this.webreq_users);
  }
  isUefaUser(): boolean {
    return this.checkUserAccess(this.uefa_users);
  }
  isPatient(): boolean {
    return this.checkUserAccess(this.patient_users);
  }
  isCustomerCare(): boolean {
    return this.checkUserAccess(this.customer_care);
  }
  isColombiaAdmin(): boolean {
    return this.checkUserAccess(this.co_admin);
  }
  isDenmarkAdmin(): boolean {
    return this.checkUserAccess(this.dk_admin);
  }
  isPortugalAdmin(): boolean {
    return this.checkUserAccess(this.pt_admin);
  }
  isAirAdmin(): boolean {
    return this.checkUserAccess(this.air_admin);
  }
  isBCP(): boolean {
    return this.checkUserAccess(this.bcp_user);
  }

  isCountryAdmin(): boolean {
    return this.checkUserAccess(
      this.co_admin.concat(
        this.pt_admin,
        this.air_admin,
        this.dk_admin,
        this.de_admin,
        this.es_admin,
        this.it_admin,
        this.ee_admin,
        this.uk_dfp_admin,
        this.uk_shfy_admin
      )
    );
  }
  isUserAdmin(): boolean {
    return this.checkUserAccess(this.useradmin);
  }
  isSchoolAdmin(): boolean {
    return this.checkUserAccess([this.school_admin]);
  }
  isSchoolTeacher(): boolean {
    return this.checkUserAccess([this.school_teacher]);
  }
  isLolliSuperAdmin(): boolean {
    return this.checkUserAccess([this.lolli_superadmin]);
  }
  isMassCompany(): boolean {
    return this.checkUserAccess([this.mass_company]);
  }
}
