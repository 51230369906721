import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { appFeatureKey } from "../reducers/app.reducer";

const State = (state: AppState) => state[appFeatureKey];

const isLanguageLoading = createSelector(State, ({ loadingJSON }) => loadingJSON);
const getActiveLanguage = createSelector(State, ({ activeLanguage }) => activeLanguage);

export const AppSelector = {
  isLanguageLoading,
  getActiveLanguage,
};
