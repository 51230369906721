import { Action } from "@ngrx/store";
import { LegalDocumentStateInterface } from "src/app/interfaces/legal-document-state.interface";
import { LegalDocumentVersionInterface } from "src/app/interfaces/legal-document-version.interface";
import { LegalDocumentInterface } from "src/app/interfaces/legal-document.interface";

export function searchLegalDocumentsReducer(state: LegalDocumentStateInterface): LegalDocumentStateInterface {
  return {
    ...state,
    documents: {
      ...state.documents,
      isLoading: true,
    },
  };
}
export function searchLegalDocumentsSuccessReducer(
  state: LegalDocumentStateInterface,
  action: Action & { legalDocuments: LegalDocumentInterface[] }
): LegalDocumentStateInterface {
  return {
    ...state,
    documents: {
      ...state.documents,
      isLoading: false,
      list: action.legalDocuments,
    },
  };
}

export function searchLegalDocumentsVersionsReducer(state: LegalDocumentStateInterface): LegalDocumentStateInterface {
  return {
    ...state,
    versions: {
      ...state.versions,
      isLoading: true,
    },
  };
}
export function searchLegalDocumentsVersionsSuccessReducer(
  state: LegalDocumentStateInterface,
  action: Action & { versions: LegalDocumentVersionInterface[] }
): LegalDocumentStateInterface {
  return {
    ...state,
    versions: {
      ...state.versions,
      isLoading: false,
      list: action.versions,
    },
  };
}
