import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: "app-general-terms",
  templateUrl: "./general-terms.component.html",
  styleUrls: ["./general-terms.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTermsComponent {
  langCode$ = this.route.queryParamMap.pipe(
    map((x) => {
      const langCode = x.get("langCode");
      return langCode ? `${langCode}|static` : "";
    })
  );
  constructor(private route: ActivatedRoute) {}
}
