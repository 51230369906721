import { createReducer, on } from "@ngrx/store";
import { ConsentsPayload } from "src/app/pages/index/pages/notification-settings/notification.model";
import { ProfileActions } from "../actions/profile.actions";

export const profileFeatureKey = "profile";

export interface ProfileInfo {
  phone: string;
  userId: string;
  ssn: string;
  locale: string;
  country: string;
  user_type: string[];
  given_name: string;
  family_name: string;
  date_of_birth: string;
  email: string;
  synlab_id: string;
  userid: string;
}

export interface Consents {
  consentId: any;
  name: string;
  description: string;
  tooltipDescription: string;
  allowed?: boolean;
  dateUpdated?: string;
}

export interface State {
  profile_data: any;
  authenticator_uri: any;
  code: string;
  phone: any;
  phone_temp: any;
  two_factor_enabled: boolean;
  residenceCountryCode: string;
  profile: ProfileInfo;
  consents: Consents[];
  consents_payload: ConsentsPayload[];
  consentLoading: boolean;
  isMarketingConsentChecked?: boolean;
  invalidProfileFormFields: string[];
}

export const initialState: State = {
  profile_data: {
    user_type: null,
  },
  authenticator_uri: null,
  code: null,
  phone: {
    number: null,
    country_phone: null,
    country_residence: null,
    country: null,
  },
  phone_temp: {
    number: null,
    country_phone: null,
  },
  two_factor_enabled: null,
  residenceCountryCode: null,
  profile: null,
  consents: null,
  consents_payload: null,
  consentLoading: false,
  isMarketingConsentChecked: false,
  invalidProfileFormFields: [],
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.loadProfiles, (state, { data }) => ({
    ...state,
    profile_data: data,
    phone: {
      number: data.phone,
      country_phone: data.country_phone,
      country_residence: data.country_residence,
      country: data.country,
    },
  })),
  on(ProfileActions.getAuthenticatorUri, (state, { data }) => ({
    ...state,
    authenticator_uri: data,
  })),
  on(ProfileActions.getRecoveryCodes, (state, { data }) => ({
    ...state,
    code: data,
  })),
  on(ProfileActions.updatePhoneDetails, (state, { number, country_phone }) => ({
    ...state,
    phone: {
      number: number,
      country_phone: country_phone,
      country_residence: state.phone.country_residence,
      country: state.phone.country,
    },
  })),
  on(ProfileActions.twoFaEnableSuccess, (state, { data }) => ({
    ...state,
    two_factor_enabled: data,
  })),
  on(ProfileActions.updatePhoneTemp, (state, { number, country_phone }) => ({
    ...state,
    phone_temp: {
      number: number,
      country_phone: country_phone,
    },
  })),
  on(ProfileActions.setResidence, ProfileActions.restoreResidence, (state, { countryCode }) => ({
    ...state,
    residenceCountryCode: countryCode,
  })),
  on(ProfileActions.getProfileSuccess, (state, { data }) => ({
    ...state,
    profile: data,
  })),
  on(ProfileActions.getConsent, (state) => ({
    ...state,
    consentLoading: true,
  })),
  on(ProfileActions.getConsentSuccess, (state, { data }) => ({
    ...state,
    consents: data,
    consentLoading: false,
  })),
  on(ProfileActions.getMarketingConsentsCheckedSuccess, (state, { value }) => ({
    ...state,
    isMarketingConsentChecked: value,
  })),
  on(ProfileActions.getConsentFailed, (state) => ({
    ...state,
    consentLoading: false,
  })),
  on(ProfileActions.consentOnchanges, (state, { consents }) => ({
    ...state,
    consents_payload: getConsentPayload(consents),
  })),
  on(ProfileActions.clearConsentPayload, (state) => ({
    ...state,
    consents: null,
    consents_payload: null,
  })),
  on(ProfileActions.AddInvalidMyProfileFormFields, (state, { name }) => ({
    ...state,
    invalidProfileFormFields: state.invalidProfileFormFields.concat(name),
  })),
  on(ProfileActions.RemoveInvalidMyProfileFormFields, (state, { name }) => ({
    ...state,
    invalidProfileFormFields: state.invalidProfileFormFields.filter((invalidField) => invalidField != name),
  })),
  on(ProfileActions.ResetInvalidMyProfileFormFields, (state) => ({
    ...state,
    invalidProfileFormFields: [],
  }))
);

function getConsentPayload(consent: Consents[]): ConsentsPayload[] {
  return consent.map((d) => ({ ConsentId: d.consentId, Allowed: d.allowed }));
}
