import { CommonModule, DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, NgModule } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { ConfigService } from "src/app/services/config/config.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoService } from "@ngneat/transloco";

export type BrowserRedirectData = {
  app: string,
  path?: string,
  url?: string
}
@Component({
  selector: 'lib-redirect',
  templateUrl: './browser-redirect.component.html',
  styleUrls: ['./browser-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserRedirectComponent {
  target:string;

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private _r:ActivatedRoute,
    private _cfg:ConfigService,
    private _t:TranslocoService,
  ) {
    const dest:BrowserRedirectData = {
      url: this._r.snapshot.data["url"],
      path: this._r.snapshot.data["path"],
      app: this._r.snapshot.data["app"]
    };
    if (dest.url) {
      this.target = dest.url;
    } else {
      this.target = this._cfg._envConfig[dest.app] + dest.path;
    }
    this._doc.location.href = this.target + '/?langCode=' + this._t.getActiveLang() ;
  }
}

@NgModule({
  imports: [CommonModule, RouterModule, MatProgressSpinnerModule],
  declarations: [BrowserRedirectComponent],
  exports: [BrowserRedirectComponent]
})
export class BrowserRedirectModule {}
