import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { select, Store } from "@ngrx/store";
import DOMPurify from "dompurify";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, shareReplay, tap, flatMap, finalize, take, takeWhile } from "rxjs/operators";
import { ProfileActions } from "src/app/@store/actions/profile.actions";
import { AppState } from "src/app/@store/reducers";
import { ProfileSelectors } from "src/app/@store/selectors/profile.selectors";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { addTargetBlankToAnchors } from "src/app/plasma-ui-common/utils/add-target-blank-to-anchors";
import { AgreementType, AgreementService } from "src/app/services/agreement/agreement.service";
import { I18nService } from "src/app/services/i18n/i18n.service";
import { isInAvailableLocale } from "src/app/transloco-root.module";

@Component({
  selector: "app-managed-privacy-policy",
  templateUrl: "./managed-privacy-policy.component.html",
  styleUrls: ["./managed-privacy-policy.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagedPrivacyPolicyComponent implements OnInit {
  html$: Observable<any>;
  langCode$: Observable<any>;
  loading: boolean;

  private localeClaim$ = this.store.pipe(select(ProfileSelectors.getLocale));
  private isLoggedIn$ = this.store.pipe(select(StsSelectors.isLoggedIn));
  firstLoad$ = new BehaviorSubject(true);

  constructor(
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private i18n: I18nService,
    private agreement: AgreementService,
    private sanitizer: DomSanitizer,
    private changeRef: ChangeDetectorRef,
    private store: Store<AppState>
  ) {}

  detectChanges(fn: () => void) {
    fn();
    this.changeRef.detectChanges();
  }

  ngOnInit() {
    this.isLoggedIn$.pipe(take(1)).subscribe((n) => {
      if (n) {
        // load profile to get "locale" claims
        this.store.dispatch(ProfileActions.getProfile());
        this.isLoggedIn();
      } else {
        this.isLoggedOut();
      }
    });
  }

  isLoggedIn() {
    this.loading = false;
    this.langCode$ = combineLatest([
      this.localeClaim$,
      this.agreement.getLocales(),
      this.route.queryParamMap.pipe(map((x) => x.get("langCode"))),
      this.transloco.langChanges$,
    ]).pipe(
      map(([userLocale, locales, qsLang, translocoLang]) => {
        const l = qsLang && this.firstLoad$.getValue() ? qsLang : translocoLang;
        const activeLang = userLocale && this.firstLoad$.getValue() ? userLocale : l;
        this.firstLoad$.next(false);
        if (activeLang) {
          return this.i18n.getPreferredSupportedLanguage(
            [activeLang],
            locales.map((l) => l.cultureCode)
          );
        } else {
          return "";
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    const type = this.route.snapshot.data["page"];
    this.html$ = combineLatest([of(type), this.langCode$]).pipe(
      tap(() => this.detectChanges(() => (this.loading = true))),
      flatMap(([type, lang]) => this.agreement.getPublishedAgreementByLocale(type, lang)),
      map((x) => x.files[0]),
      map((x) => (x ? x.htmlFile : "")),
      map((html) => {
        const purified = DOMPurify.sanitize(html, {
          ADD_TAGS: ["link"],
          ADD_ATTR: ["href", "rel"],
          FORCE_BODY: true,
        });
        this.loading = false;
        return this.sanitizer.bypassSecurityTrustHtml(addTargetBlankToAnchors(purified));
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
      tap(() => this.detectChanges(() => (this.loading = false))),
      finalize(() => {
        this.detectChanges(() => (this.loading = false));
      })
    );
  }

  isLoggedOut() {
    this.loading = false;
    this.langCode$ = combineLatest([
      this.agreement.getLocales(),
      this.route.queryParamMap.pipe(map((x) => x.get("langCode"))),
      this.transloco.langChanges$,
    ]).pipe(
      map(([locales, qsLang, translocoLang]) => {
        const activeLang = qsLang && this.firstLoad$.getValue() ? qsLang : translocoLang;
        if (activeLang) {
          if (this.firstLoad$.getValue()) {
            this.firstLoad$.next(false);
            const inAvailableLang = isInAvailableLocale(activeLang, this.transloco.getAvailableLangs() as string[]);
            if (inAvailableLang != "") {
              this.transloco.setActiveLang(inAvailableLang);
            }
          }
          return this.i18n.getPreferredSupportedLanguage(
            [activeLang],
            locales.map((l) => l.cultureCode)
          );
        } else {
          return "";
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    const type = this.route.snapshot.data["page"];
    this.html$ = combineLatest([of(type), this.langCode$]).pipe(
      takeWhile(() => !this.firstLoad$.getValue()),
      tap(() => this.detectChanges(() => (this.loading = true))),
      flatMap(([type, lang]) => this.agreement.getPublishedAgreementByLocale(type, lang)),
      map((x) => x.files[0]),
      map((x) => (x ? x.htmlFile : "")),
      map((html) => {
        const purified = DOMPurify.sanitize(html, {
          ADD_TAGS: ["link"],
          ADD_ATTR: ["href", "rel"],
          FORCE_BODY: true,
        });
        return this.sanitizer.bypassSecurityTrustHtml(addTargetBlankToAnchors(purified));
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
      tap(() => this.detectChanges(() => (this.loading = false))),
      finalize(() => this.detectChanges(() => (this.loading = false)))
    );
  }
}
