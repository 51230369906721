import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

export interface CountryInfo {
  countryName: string;
  cultureCode: string;
  isSupported: boolean;
  ssnMaskCode: string;
  ssnRegex: string;
  twoLetterCountryCode: string;
}

@Injectable({
  providedIn: "root",
})
export class CountryService {
  constructor(private apiService: ApiService) {}

  public async getByCode(code: string): Promise<CountryInfo> {
    return this.apiService.get<CountryInfo>(`/manage/country/code/${code}`).toPromise();
  }
}
