import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { EStorageKeys } from "../models/storage.model";
import { ConfigService } from "../services/config/config.service";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard {
  constructor(private config: ConfigService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = this.config?._envConfig.BaseProfileUrl || "";
    window.localStorage.setItem(EStorageKeys.REDIRECT_URL, window.location.href.replace(url, ""));
    return true;
  }
}
