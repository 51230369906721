import { ProfileEffects } from "./profile.effects";
import { AppEffects } from "./app.effects";
import { RegistrationEffects } from "./registration.effects";
import { RegisterProfileEffects } from "src/app/@store/effects/register-profile.effects";
import { TaraRegisterProfileEffects } from "src/app/@store/effects/tara-register-profile.effects";
import { StsEffects } from "./sts.effects";

import { DkMfaResetEffects } from "../dk-mfa-reset/dk-mfa-reset.effects";
import { LegalDocumentsEffects } from "../legal-documents/legal-documents.effects";
export const effects: any[] = [
  ProfileEffects,
  AppEffects,
  RegistrationEffects,
  RegisterProfileEffects,
  TaraRegisterProfileEffects,
  StsEffects,
  DkMfaResetEffects,
  LegalDocumentsEffects,
];
