import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { auditTime, catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { IClaim } from "src/app/view/dk-mfa-reset/enum-dk-mfa";
import { AppState } from "../reducers";
import { DkMfaResetAction } from "./dk-mfa-reset.actions";
import { DKMFAResetSelector } from "./dk-mfa-reset.selectors";

@Injectable()
export class DkMfaResetEffects {
  phone$ = this._st.pipe(select(DKMFAResetSelector.getPhone));

  constructor(
    private actions$: Actions,
    private _api: ApiService,
    private _st: Store<AppState>,
    private _tostr: ToastrService,
    private _rt: Router,
    private _lang: TranslocoService
  ) {}

  validateMFAResetLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.validateMFAResetLink),
        switchMap(({ payload }) => this._api.get("/manage/accounts/mfa_reset_isvalid", payload)),
        map((res) => {
          this._st.dispatch(DkMfaResetAction.validateMFAResetLinkSuccess({ value: res }));
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.validateMFAResetLinkFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  getMFAResetClaims$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.getMFAResetClaims),
        auditTime(120),
        switchMap(({ params }) => this._api.get("/manage/accounts/mfa_reset_info", params)),
        map((res: IClaim[]) => {
          const claims = res.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {});
          this._st.dispatch(DkMfaResetAction.getMFAResetClaimsSuccess({ claims }));
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.getMFAResetClaimsFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  changePhone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.changePhone),
        switchMap(({ params, body }) =>
          this._api.put("/manage/accounts/reset_change_phone", body, {
            params,
          })
        ),
        map((res) => {
          this._st.dispatch(DkMfaResetAction.changePhoneSuccess({ data: res }));
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.changePhoneFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  verifyChangePhone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.verifyChangePhone),
        switchMap(({ params, body }) =>
          this._api.put("/manage/accounts/reset_verify_phone", body, {
            params,
          })
        ),
        map((res) => {
          if (res) {
            this._st.dispatch(DkMfaResetAction.verifyChangePhoneSuccess({ data: res }));
          } else {
            this.showError("INVALID_OTP_CODE");
            this._st.dispatch(DkMfaResetAction.verifyChangePhoneFailed({ error: "INVALID_OTP_CODE" }));
          }
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.verifyChangePhoneFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  set2FA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.set2FA),
        withLatestFrom(this.phone$),
        switchMap(([{ params, body }, phone]) =>
          this._api
            .put("/manage/accounts/reset_set_two_factor_authentication", body, {
              params,
            })
            .pipe(
              map((n) => ({
                data: n,
                mfaType: body.twoFactorProvider,
                phone: phone,
              }))
            )
        ),
        map(({ data, mfaType, phone }) => {
          this._st.dispatch(DkMfaResetAction.set2FASuccess({ data, mfaType, phone }));
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.set2FAFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  verifySet2FA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DkMfaResetAction.verifySet2FA),
        switchMap(({ params, body }) =>
          this._api.put("/manage/accounts/reset_verify_two_factor_authentication", body, { params })
        ),
        map((res) => {
          if (res) {
            this._st.dispatch(DkMfaResetAction.verifySet2FASuccess({ data: res }));
            this._rt.navigate(["/"]);
          } else {
            this.showError("INVALID_OTP_CODE");
            this._st.dispatch(DkMfaResetAction.verifySet2FAFailed({ error: "INVALID_OTP_CODE" }));
          }
        }),
        catchError((error, caught) => {
          this.showError(error);
          this._st.dispatch(DkMfaResetAction.verifySet2FAFailed({ error }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  private showError(error: HttpErrorResponse | string) {
    const errorMessage: string = this._lang.translate("taraActivationComponent.anUnexpectedErrorHasOccured");

    if (typeof error == "string") {
      const message: string = this._lang.translate(`errorMessage.${error}`);

      this._tostr.error(message || errorMessage);
    } else {
      const key: string = error && error.error && error.error.ErrorMessage;
      const message: string = this._lang.translate(`errorMessage.${key}`);

      this._tostr.error(message || errorMessage);
    }
  }
}
