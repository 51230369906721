import { Router } from "@angular/router";
import { ConfigService } from "src/app/services/config/config.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-init",
  templateUrl: "./init.component.html",
  styleUrls: ["./init.component.scss"],
})
export class InitComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private conf: ConfigService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.conf.load();
    this.router.navigate(["account"]);
  }
}
