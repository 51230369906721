import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LegalDocumentVersionInterface } from "src/app/interfaces/legal-document-version.interface";
import { LegalDocumentInterface } from "src/app/interfaces/legal-document.interface";
import { ConfigService } from "src/app/services/config/config.service";

@Injectable({
  providedIn: "root",
})
export class LegalDocumentsService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService
  ) {}

  public searchLegalDocuments(userId: string) {
    return this.http.get<LegalDocumentInterface[]>(
      `${this.configService.baseAuthUrl}/manage/agreement/GetUserLegalDocuments/${userId}`
    );
  }

  public searchLegalDocumentsVersions(userId: string, type: string) {
    return this.http.get<LegalDocumentVersionInterface[]>(
      `${this.configService.baseAuthUrl}/manage/agreement/GetUserLegalDocumentVersions/${userId}/${type}`
    );
  }
}
