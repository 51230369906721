<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet" />

<div data-test-id="general-terms-container"  class="container">
  <div data-test-id="general-terms-navbar-brand"  class="row navbar-brand">
    <div data-test-id="general-terms-navbar-brand-column"  class="col-12">
      <img data-test-id="general-terms-synlab-logo"  src="assets/img/synlab-logo.svg" alt="Synlab's logo" class="pl-logo-1 mt-4 mb-2" />
    </div>
  </div>
</div>
<div data-test-id="general-terms-container"  class="container body text-synlab mt-5" *transloco="let t; read: 'generalTerms'; lang: langCode$ | async">
  <div data-test-id="general-terms-container-row"  class="row">
    <div data-test-id="general-terms-container-row-column"  class="col-12">
      <div data-test-id="general-terms-title" >
        <h2 data-test-id="general-terms-title-h2"  class="title" innerHtml="{{ t('title') }}"></h2>
        <h2 data-test-id="general-terms-subtitle"  class="subtitle" innerHtml="{{ t('subtitle') }}"></h2>
      </div>
      <ol data-test-id="general-terms-general-terms" >
        <li data-test-id="general-terms-general-terms-item-1"  innerHtml="{{ 'generalTerms.item1' | transloco: null : 'en' }}"></li>
        <li data-test-id="general-terms-general-terms-item-2"  innerHtml="{{ t('item2') }}"></li>
        <li data-test-id="general-terms-general-terms-item-3"  innerHtml="{{ t('item3') }}"></li>
        <li data-test-id="general-terms-general-terms-item-4"  innerHtml="{{ t('item4') }}"></li>
        <li data-test-id="general-terms-general-terms-item-5"  innerHtml="{{ t('item5') }}"></li>
        <li data-test-id="general-terms-general-terms-item-6"  innerHtml="{{ t('item6') }}"></li>
        <li data-test-id="general-terms-general-terms-item-7"  innerHtml="{{ t('item7') }}"></li>
        <li data-test-id="general-terms-general-terms-item-8"  innerHtml="{{ t('item8') }}"></li>
        <li data-test-id="general-terms-general-terms-item-9"  innerHtml="{{ t('item9') }}"></li>
        <li data-test-id="general-terms-general-terms-item-10"  innerHtml="{{ t('item10') }}"></li>
        <li data-test-id="general-terms-general-terms-item-11"  innerHtml="{{ t('item11') }}"></li>
        <li data-test-id="general-terms-general-terms-item-12"  innerHtml="{{ t('item12') }}"></li>
        <li data-test-id="general-terms-general-terms-item-13"  innerHtml="{{ t('item13') }}"></li>
      </ol>
    </div>
  </div>
</div>
