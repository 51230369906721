import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { merge, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, withLatestFrom, tap } from "rxjs/operators";
import * as RegisterProfileActions from "src/app/@store/actions/register-profile.actions";
import { AUTH_METHOD, claimTypes, claimValues, queryParamKey, whiteLabelNames } from "src/app/appsettings";
import { RegisterProfileApiService } from "src/app/services/register-profile-api/register-profile-api.service";
import { getErrorMessage } from "src/app/utils/error.util";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { EStorageKeys } from "src/app/models/storage.model";
@Injectable()
export class RegisterProfileEffects {
  private isFinland$ = this.store.select(StsSelectors.isFITenant);

  constructor(
    private actions$: Actions,
    private registerProfileApi: RegisterProfileApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) {}
  loadRegisterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterProfileActions.loadRegisterProfile),
      mergeMap(({ regId, seqId, bankId }) => {
        if (bankId) {
          return this.registerProfileApi.getRegisterProfileForCzechRepublic(bankId);
        }
        return this.registerProfileApi.getRegisterProfile(regId, seqId);
      }),
      map((claims) => RegisterProfileActions.loadRegisterProfileSuccess({ data: claims })),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(of(RegisterProfileActions.loadRegisterProfileFailure({ error: msg, errorObject: error })), caught);
      })
    )
  );

  loadExistingPTProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterProfileActions.loadExistingPTProfile),
      mergeMap((action) => this.registerProfileApi.getPtExisitngProfile(action.userId)),
      withLatestFrom(this.isFinland$),
      map(([claims, isFinland]) => {
        if (isFinland) {
          // we need to update query params, for finland, no userId supplied in the url, so we need to update query string to add userid
          const userIdEncoded: string = btoa(
            claims.find((claim) => claim.claimType == claimTypes.USERID)?.claimValue || ""
          );

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { [queryParamKey.USER_ID]: userIdEncoded },
            queryParamsHandling: "merge",
          });
        }

        return RegisterProfileActions.loadExistingPTProfileSuccess({ data: claims });
      }),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(
            RegisterProfileActions.loadExistingPTProfileFailure({
              error: msg,
              errorObject: error,
            })
          ),
          caught
        );
      })
    )
  );

  submitRegisterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterProfileActions.submitRegisterProfile),
      mergeMap((action) =>
        this.registerProfileApi
          .submitRegisterProfile(action.regId, action.data)
          .pipe(map((res) => [res, action] as const))
      ),
      switchMap(([res, action]) =>
        this.registerProfileApi.updateConsents(action.consents, action.synlabId, action.seqId)
      ),
      map(() => RegisterProfileActions.submitRegisterProfileSuccess()),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(RegisterProfileActions.submitRegisterProfileFailure({ error: msg, errorObject: error })),
          caught
        );
      })
    )
  );

  updatePtExisitingProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterProfileActions.updatePtExistingProfile),
      withLatestFrom(this.isFinland$),
      mergeMap(([action, isFinland]) => {
        const tenant: string = isFinland ? whiteLabelNames.FINLAND : "";
        return this.registerProfileApi.updatePtExistingProfile(action.userId, action.data, tenant).pipe(
          withLatestFrom(this.store.select(StsSelectors.getBaseBookingUrl)),
          tap(([result, bookingUrl]) => {
            if (isFinland) {
              if (bookingUrl) {
                window.location.assign(bookingUrl);
              }

              const { returnUrl } = result as any;
              localStorage.setItem(EStorageKeys.REGISTER_REDIRECT_URL, returnUrl);
            }
          }),
          map((res) => [res, action] as const)
        );
      }),
      switchMap(([res, action]) =>
        this.registerProfileApi.updateConsents(action.consents, action.synlabId, action.seqId)
      ),
      map(() => RegisterProfileActions.updatePtExistingProfileSuccess()),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(RegisterProfileActions.updatePtExistingProfileFailure({ error: msg, errorObject: error })),
          caught
        );
      })
    )
  );

  signupToTaraCLicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterProfileActions.signupToTaraClicked),
      mergeMap((action) => this.registerProfileApi.rollBackUser(action.userId)),
      map(() => RegisterProfileActions.signupToTaraClickedSuccess()),
      catchError((error: HttpErrorResponse, caught) => {
        const msg = getErrorMessage(error);
        return merge(
          of(
            RegisterProfileActions.signupToTaraClickedFailure({
              error: msg,
              errorObject: error,
            })
          ),
          caught
        );
      })
    )
  );
}
