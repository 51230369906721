<dxp-background data-test-id="dxp-send-forgot-password-dxp-background" ></dxp-background>

<div data-test-id="dxp-send-forgot-password-forgot-password-wrapper"  class="forgot-password-wrapper" *transloco="let t; read: 'sendForgotPassword'">
  <div data-test-id="dxp-send-forgot-password-forgot-password-card"  class="forgot-password-card">
    <h1 data-test-id="dxp-send-forgot-password-forgot-password-title"  class="forgot-password-title">{{ t("shfyHeading") }}</h1>
    <p data-test-id="dxp-send-forgot-password-message" class="forgot-password-message mb-0">{{ t("shfyMessage") }}</p>

    <form data-test-id="dxp-send-forgot-password-form"  [formGroup]="form" (submit)="onSubmit()">
      <div data-test-id="dxp-send-forgot-password-input-group"  class="forgot-password-input-group">
        <input data-test-id="dxp-send-forgot-password-email-control" 
          formControlName="email"
          type="email"
          class="forgot-password-input"
          placeholder="Email"
          [ngClass]="{ 'is-invalid': form.invalid && form.touched }"
        />
        <span data-test-id="dxp-send-forgot-password-email-clear-icon"  *ngIf="email.value" class="input-clear-icon" (click)="onClear()">
          <em data-test-id="dxp-send-forgot-password-times-icon"  class="fa fa-times"></em>
        </span>
      </div>

      <div data-test-id="dxp-send-forgot-password-action-wrapper"  class="action-wrapper">
        <button data-test-id="dxp-send-forgot-password-cancel-btn"  (click)="onCancel()" type="button" class="cancel-btn sm-margin">
          {{ t("shfyCancel") }}
        </button>
        <button data-test-id="dxp-send-forgot-password-forgot-password-btn" class="forgot-password-btn sm-margin">
          <i data-test-id="dxp-send-forgot-password-forgot-password-btn-icon" class="fa fa-arrow-right forgot-password-btn-icon" aria-hidden="true"></i>
          {{ t("shfyProceed") }}
        </button>
      </div>
    </form>
  </div>

  <div data-test-id="dxp-send-forgot-password-alert-message" 
    *ngIf="showMessage && submitted"
    class="alert alert-success"
    [innerHTML]="formatSuccessMessage(t('shfySendSuccess'))"
  ></div>
</div>
