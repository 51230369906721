import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { ActivateAccountComponent } from "./activate-account.component";
import { TranslocoModule } from "@ngneat/transloco";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [ActivateAccountComponent],
  imports: [CommonModule, SharedModule, MatProgressSpinnerModule, TranslocoModule],
})
export class ActivateAccountModule {}
