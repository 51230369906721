<ng-container *transloco="let t; read: 'forgotPassword'">
  <div data-test-id="forgot-password-background-wrapper"  *ngIf="isSHFYInstance$ | async" class="background">
    <img data-test-id="forgot-password-background"  src="/assets/dxp/img/background.png" alt="" srcset="" />
  </div>

  <ng-container *ngIf="!(useNewNav$ | async)">
    <app-header data-test-id="forgot-password-header"  [showDropdown]="false"></app-header>
  </ng-container>
  <div data-test-id="forgot-password-container" class="container" *ngIf="showSpin">
    <p data-test-id="forgot-password-container-text-center" class="text-center pt-5 pb-2">
      <img data-test-id="forgot-password-spinner"  src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner d-inline" />
    </p>
  </div>

  <div data-test-id="forgot-password-container" class="container" *ngIf="!showSpin && !showForm">
    <ng-container *ngIf="useNewNav$ | async; else oldWarning">
      <div data-test-id="forgot-password-syn-container"  class="syn-container">
        <h2 data-test-id="forgot-password-invalid-link-text" >{{ t("invalidLinkText") }}</h2>
        <button data-test-id="forgot-password-redirect-to-contact-synlab"  (click)="redirectToContactSynlab()">
          <mat-icon data-test-id="forgot-password-arrow-forward" >arrow_forward</mat-icon>
          {{ "completeProfile.openUefaLink" | transloco }}
        </button>
      </div>
    </ng-container>
    <ng-template #oldWarning>
      <div data-test-id="forgot-password-alert-warning"  class="alert alert-warning mt-5" role="alert">
        {{ t("invalidLinkText") }}
      </div>
    </ng-template>
  </div>

  <div data-test-id="forgot-password-shfy-container"  [ngClass]="{ 'is-dxp': (isSHFYInstance$ | async) }" class="container pt-5 pb-5" *ngIf="!showSpin && showForm">
    <div data-test-id="forgot-password-shfy-row"  class="row">
      <div data-test-id="forgot-password-shfy-column"  class="col-12 col-sm-12">
        <div data-test-id="forgot-password-profile-secure-wrappper"  class="card profile-secure-wrappper pl-box-shadow-1">
          <div data-test-id="forgot-password-card-body"  class="card-body pb-5 p-0">
            <h3 data-test-id="forgot-password-reset-your-password-title" class="title-mb f-6 py-3 px-4">{{ t("resetYourPasswordTitle") }}</h3>
            <p data-test-id="forgot-password-loading-wrapper" class="text-center pt-5 pb-2 mb-5" *ngIf="loading">
              <img data-test-id="forgot-password-loading-spinner"  src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner d-inline" />
            </p>
            <div data-test-id="forgot-password-alert-wrapper" class="wrapper py-3 px-4" *ngIf="!loading">
              <div data-test-id="forgot-password-alert-danger" class="alert alert-danger" role="alert" hidden>
                <p data-test-id="forgot-password-alert-content" class="text-center">
                  <strong data-test-id="forgot-password-note-text" >{{ t("noteText") }}:</strong>
                  <br />
                  {{ t("completeRegistrationText.text1") }}
                  <br />
                  {{ t("completeRegistrationText.text2") }}
                </p>
              </div>

              <!-- Account Password -->
              <div data-test-id="forgot-password-row"  class="row">
                <div data-test-id="forgot-password-column"  class="col-12 col-sm-12">
                  <form data-test-id="forgot-password-password-form"  autocomplete="off" [formGroup]="passwordFormG" (ngSubmit)="setAccountPassword()">
                    <div data-test-id="forgot-password-password-form-centered"  class="row justify-content-center" *ngIf="passwordFormG as fp">
                      <div data-test-id="forgot-password-form-groups-wrapper"  class="col-12 col-sm-9 pt-4">
                        <ng-container *ngIf="isSHFYInstance$ | async">
                          <ng-container *ngTemplateOutlet="passwordHelpBlock"></ng-container>
                        </ng-container>

                        <!-- new password -->
                        <div data-test-id="forgot-password-form-group"  class="form-group">
                          <label data-test-id="forgot-password-form-group-label"  *ngIf="!(isSHFYInstance$ | async)" class="f-2" for="">{{
                            t("enterPasswordLabel")
                          }}</label>
                          <input data-test-id="forgot-password-form-group-control" 
                            type="password"
                            class="form-control"
                            [formControlName]="EPassForm.NEW_PASS"
                            [id]="EPassForm.NEW_PASS"
                            [ngClass]="{
                              'border-danger': fp.get(EPassForm.NEW_PASS).errors && fp.get(EPassForm.NEW_PASS).dirty,
                            }"
                            [placeholder]="(isSHFYInstance$ | async) ? t('enterPasswordLabel') : ''"
                          />
                        </div>

                        <!-- confirm password -->
                        <div data-test-id="forgot-password-form-group"  class="form-group">
                          <label data-test-id="forgot-password-form-group-label"  *ngIf="!(isSHFYInstance$ | async)" class="f-2" for="">{{
                            t("confirmPasswordLabel")
                          }}</label>
                          <input data-test-id="forgot-password-form-group-control" 
                            type="password"
                            class="form-control"
                            [formControlName]="EPassForm.CONFIRM_PASS"
                            [id]="EPassForm.CONFIRM_PASS"
                            [ngClass]="{
                              'border-danger': fp.errors?.confirmPassword && fp.get(EPassForm.CONFIRM_PASS).dirty,
                            }"
                            [placeholder]="(isSHFYInstance$ | async) ? t('confirmPasswordLabel') : ''"
                          />
                          <small data-test-id="forgot-password-error"  *ngIf="fp.errors?.confirmPassword" class="text-danger">
                            {{ t("passwordDontMatchError") }}
                          </small>

                          <ng-container *ngIf="!(isSHFYInstance$ | async)">
                            <ng-container *ngTemplateOutlet="passwordHelpBlock"></ng-container>
                          </ng-container>
                        </div>

                        <!-- submit button -->
                        <div data-test-id="forgot-password-btns-wrapper"  class="buttons mt-4">
                          <button data-test-id="forgot-password-save-pass-btn" 
                            [disabled]="fp.invalid"
                            id="save_pass_button"
                            class="btn btn-primary px-4 float-right"
                            type="submit"
                          >
                            <i
                              *ngIf="isSHFYInstance$ | async"
                              data-test-id="forgot-password-forgot-password-btn-icon"
                              class="fa fa-arrow-right forgot-password-btn-icon"
                              aria-hidden="true"
                            ></i>
                            {{ t("savePasswordButton") }}
                          </button>
                          <span data-test-id="forgot-password-clearfix"  class="clearfix"></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #passwordHelpBlock>
    <small data-test-id="forgot-password-password-help-block" id="passwordHelpBlock" class="form-text pt-2 text-muted">
      {{ t("passwordInstructionText") }}
    </small>
  </ng-template>
</ng-container>
