import { HttpErrorResponse } from "@angular/common/http";
import { createReducer, on } from "@ngrx/store";
import { TaraUser } from "src/app/models/tara-user.model";
import * as TaraRegisterProfileActions from "src/app/@store/actions/tara-register-profile.actions";
export const featureKey = "taraRegisterProfile";
export interface State {
  registerProfile: TaraUser | undefined;
  registerProfileLoadError: string | undefined;
  registerProfileLoadErrorObject: HttpErrorResponse;
  registerProfileSubmitted: boolean;
  registerProfileLoading: boolean;
  submitRegisterProfileLoading: boolean;
  submitRegisterProfileError: string | undefined;
  submitConsentLoading: boolean;
}
export const initialState: State = {
  registerProfile: undefined,
  registerProfileLoadError: undefined,
  registerProfileLoadErrorObject: undefined,
  registerProfileSubmitted: false,
  registerProfileLoading: false,
  submitRegisterProfileLoading: false,
  submitRegisterProfileError: undefined,
  submitConsentLoading: false,
};

export const reducer = createReducer<State>(
  initialState,
  on(TaraRegisterProfileActions.reset, () => ({
    ...initialState,
  })),
  on(TaraRegisterProfileActions.loadRegisterProfile, (state) => ({
    ...state,
    registerProfileLoading: true,
  })),
  on(TaraRegisterProfileActions.loadRegisterProfileSuccess, (state, { data }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfile: data,
  })),
  on(TaraRegisterProfileActions.loadRegisterProfileFailure, (state, { error, errorObject }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfileLoadError: error,
    registerProfileLoadErrorObject: errorObject,
  })),
  on(TaraRegisterProfileActions.submitRegisterProfile, (state) => ({
    ...state,
    registerProfileSubmitted: true,
    submitRegisterProfileLoading: true,
    submitRegisterProfileError: undefined,
  })),
  on(TaraRegisterProfileActions.submitRegisterProfileSuccess, (state) => ({
    ...state,
    submitRegisterProfileLoading: false,
  })),
  on(TaraRegisterProfileActions.submitRegisterProfileFailure, (state, { error }) => ({
    ...state,
    submitRegisterProfileLoading: false,
    submitRegisterProfileError: error,
  }))
);
