import { createReducer, on } from "@ngrx/store";
import { Claim } from "src/app/services/accounts/accounts.model";
import * as RegisterProfileActions from "src/app/@store/actions/register-profile.actions";
import { HttpErrorResponse } from "@angular/common/http";
export const featureKey = "registerProfile";

export interface State {
  registerProfile: Claim[] | undefined;
  registerProfileLoadError: string | undefined;
  registerProfileLoadErrorObject: HttpErrorResponse;
  registerProfileSubmitted: boolean;
  registerProfileLoading: boolean;
  submitRegisterProfileLoading: boolean;
  submitRegisterProfileError: string | undefined;
  submitConsentLoading: boolean;
}
export const initialState: State = {
  registerProfile: undefined,
  registerProfileLoadError: undefined,
  registerProfileLoadErrorObject: undefined,
  registerProfileSubmitted: false,
  registerProfileLoading: false,
  submitRegisterProfileLoading: false,
  submitRegisterProfileError: undefined,
  submitConsentLoading: false,
};

export const reducer = createReducer<State>(
  initialState,
  on(RegisterProfileActions.reset, () => ({
    ...initialState,
  })),
  on(RegisterProfileActions.loadRegisterProfile, (state) => ({
    ...state,
    registerProfileLoading: true,
  })),
  on(RegisterProfileActions.loadRegisterProfileSuccess, (state, { data }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfile: data,
  })),
  on(RegisterProfileActions.loadRegisterProfileFailure, (state, { error, errorObject }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfileLoadError: error,
    registerProfileLoadErrorObject: errorObject,
  })),
  on(RegisterProfileActions.loadExistingPTProfile, (state) => ({
    ...state,
    registerProfileLoading: true,
  })),
  on(RegisterProfileActions.loadExistingPTProfileSuccess, (state, { data }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfile: data,
  })),
  on(RegisterProfileActions.loadExistingPTProfileFailure, (state, { error, errorObject }) => ({
    ...state,
    registerProfileLoading: false,
    registerProfileLoadError: error,
    registerProfileLoadErrorObject: errorObject,
  })),
  on(RegisterProfileActions.submitRegisterProfile, (state) => ({
    ...state,
    registerProfileSubmitted: true,
    submitRegisterProfileLoading: true,
    submitRegisterProfileError: undefined,
  })),
  on(RegisterProfileActions.submitRegisterProfileSuccess, (state) => ({
    ...state,
    submitRegisterProfileLoading: false,
  })),
  on(RegisterProfileActions.submitRegisterProfileFailure, (state, { error }) => ({
    ...state,
    submitRegisterProfileLoading: false,
    submitRegisterProfileError: error,
  })),
  on(RegisterProfileActions.updatePtExistingProfile, (state) => ({
    ...state,
    registerProfileSubmitted: true,
    submitRegisterProfileLoading: true,
    submitRegisterProfileError: undefined,
  })),
  on(RegisterProfileActions.updatePtExistingProfileSuccess, (state) => ({
    ...state,
    submitRegisterProfileLoading: false,
  })),
  on(RegisterProfileActions.updatePtExistingProfileFailure, (state, { error }) => ({
    ...state,
    submitRegisterProfileLoading: false,
    submitRegisterProfileError: error,
  })),
  on(RegisterProfileActions.signupToTaraClicked, (state) => ({
    ...state,
    registerProfileLoading: true,
    submitRegisterProfileError: undefined,
  })),
  on(RegisterProfileActions.signupToTaraClickedFailure, (state, { error }) => ({
    ...state,
    registerProfileLoading: false,
    submitRegisterProfileError: error,
  }))
);
