import { NgModule, Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxpBackgroundComponent } from "./dxp-background.component";

@NgModule({
  imports: [CommonModule],
  exports: [DxpBackgroundComponent],
  declarations: [DxpBackgroundComponent],
})
export class DxpBackgroundModule {}
