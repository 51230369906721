import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

export interface AgreementFile {
  id: number;
  locale: string;
  htmlFile: string;
}
export enum AgreementType {
  TermsAndCondition = 0,
  TermsAndConditionFIProfessionals = "Terms-and-conditions-professionals",
  PrivacyPolicyFIProfessionals = "Privacy-policy-professionals",
  PrivacyPolicy = 1,
  TermsOfService = 2,
  ADDITIONAL_INFO_COLLECTED_DATA = 8,
}
export interface Agreement {
  id: number;
  agreementType: AgreementType;
  definition: string;
  createdDate: string;
  isPublished: boolean;
  datePublished: string;
  files: AgreementFile[];
}
export interface Locale {
  language: string;
  cultureCode: string;
  order?: number;
}
export interface AcceptedAgreement {
  agreement: Agreement;
  dateAgreed: string;
}
@Injectable({
  providedIn: "root",
})
export class AgreementService {
  constructor(private api: ApiService) {}

  getUserAgreements(userId: string, agreementType: AgreementType) {
    return this.api.get<AcceptedAgreement[]>(`/manage/agreement/user/${userId}/${agreementType}`);
  }
  getPublishedAgreement(agreementType: AgreementType) {
    return this.api.get<Agreement>(`/manage/agreement/published/${agreementType}`);
  }

  public getPublishedByUniqueId(uniqueId: any, locale: string) {
    return this.api.get<Agreement>(`/manage/agreement/GetPublishedByUniqueId/${uniqueId}/${locale}`);
  }

  getPublishedAgreementByLocale(agreementType: AgreementType, locale: string) {
    return this.api.get<Agreement>(`/manage/agreement/published/${agreementType}/${locale}`);
  }
  getAgreementFile(id: number, locale: string) {
    return this.api.get<AgreementFile>(`/manage/agreement/${id}/${locale}`);
  }
  getLocales() {
    return this.api.get<Locale[]>("/manage/locale");
  }
}
