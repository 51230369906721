import { Injectable } from "@angular/core";
import { Observable, of, combineLatest, BehaviorSubject } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { ConfigService } from "src/app/plasma-ui-common/synlab-access-ui-components/core";
import { ConfigService as AppConfigService } from "src/app/services/config/config.service";
import { Config } from "src/app/plasma-ui-common/synlab-access-ui-components/model/config.model";
import { select, Store } from "@ngrx/store";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { claimValues } from "src/app/appsettings";

@Injectable({
  providedIn: "root",
})
export class HeaderConfigService implements ConfigService {
  config: Config;
  configSubject: BehaviorSubject<Config>;
  config$: Observable<Config>;

  constructor(
    private _cfg: AppConfigService,
    private _store: Store
  ) {
    const {
      BaseAdminUrl,
      BaseBookingUrl,
      BaseBookingAdminUrl,
      BaseWebreqAdminUrl,
      BaseWebreqUrl,
      BasePlasmaUrl,
      BaseBigCommerceUrl,
      BaseProfileUrl,
      EeBaseBookingUrl,
      WhiteLabelEnabled,
      WhiteLabelName,
      EnableCheckResults,
    } = _cfg._envConfig;

    this.config = {
      baseAdminUrl: BaseAdminUrl,
      baseBookingUrl: BaseBookingUrl,
      baseBookingAdminUrl: BaseBookingAdminUrl,
      baseWebreqAdminUrl: BaseWebreqAdminUrl,
      eeBaseBookingUrl: EeBaseBookingUrl,
      baseWebreqUrl: BaseWebreqUrl,
      basePlasmaUrl: BasePlasmaUrl,
      baseBigcommerceUrl: BaseBigCommerceUrl,
      baseProfileUrl: BaseProfileUrl,
      EnablePreventionWellness: null,
      FullCatalogueViewEnabled: true,
      applicationId: "sts-accounts",
      WhiteLabelEnabled: WhiteLabelEnabled.toLowerCase() == "true",
      WhiteLabelName,
      EnableCheckResults: EnableCheckResults?.toLowerCase() == "true",
    };

    this.configSubject = new BehaviorSubject(this.config);

    const generateConfig = combineLatest([
      this._store.pipe(select(StsSelectors.isGenePlanetEnrolled)),
      this._store.pipe(select(StsSelectors.hasGeneplanetOrders)),
    ]).pipe(
      filter(([enrolled]) => enrolled !== null),
      map(([enrolled, canEnroll]) => enrolled || canEnroll),
      map((res) => {
        if (this._cfg._envConfig.EnablePreventionWellness.toLowerCase() != claimValues.TRUE) {
          return false;
        }
        return res;
      }),
      tap((res) => {
        if (res === null) {
          this._store.dispatch(STSActions.fetchHasGeneplanetOrders());
        }
        return of(res);
      }),
      map((EnablePreventionWellness) => ({
        ...this.config,
        EnablePreventionWellness,
      })),
      tap((config) => this.configSubject.next(config))
    );

    generateConfig.subscribe();
    this.config$ = this.configSubject.asObservable();
  }
}
