import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";
import { map } from "rxjs/operators";

export interface Country {
  Code: string;
  Name: string;
  SmallName: string;
}

@Injectable({ providedIn: "root" })
export class CountryService {
  public countriesAsObj$: BehaviorSubject<Record<string, string>> = new BehaviorSubject({});

  public countries$: BehaviorSubject<Partial<Country[]>> = new BehaviorSubject([]);

  public smallCodeCountries$: Observable<Country[]> = this.countries$.pipe(
    map((countries) =>
      countries.map((country) => ({
        Name: country.Name,
        SmallName: country.Name.toLowerCase(),
        Code: country.Code.toLowerCase(),
      }))
    )
  );

  constructor() {
    this.getCountryText$ = this.getCountryText$.bind(this);
  }

  public getCountryText$(code: string): Observable<string> {
    const invalidCode: boolean = typeof code !== "string" || code.trim() === "";
    if (invalidCode) {
      return of("");
    }

    return this.smallCodeCountries$.pipe(
      map((countries) => countries.find((country) => country.Code === code.toLowerCase())?.Name || "")
    );
  }

  public filterCountries$(query: string) {
    query = query?.toLowerCase();
    if (query) {
      return this.smallCodeCountries$.pipe(
        map((countries) =>
          countries.filter((country) => country.Code.indexOf(query) > -1 || country.SmallName.indexOf(query) > -1)
        )
      );
    }
    return this.smallCodeCountries$;
  }
}
