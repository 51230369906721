import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { Config } from '../../model/config.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: CoreModule
})
export class ConfigService {
  config$: Observable<Config> = of({
    EnablePreventionWellness: false,
    FullCatalogueViewEnabled: false,
    baseAdminUrl: '/admin',
    baseBigcommerceUrl: '/big-commerce',
    baseBookingUrl: '/booking',
    baseBookingAdminUrl: '/booking',
    baseWebreqAdminUrl: '/webreq',
    baseWebreqUrl: '/webreq',
    basePlasmaUrl: '/results',
    baseProfileUrl: '/profile',
    eeBaseBookingUrl: '/ee-booking',
    WhiteLabelEnabled: false,
    WhiteLabelName: '',
    EnableCheckResults: false
  })
}
