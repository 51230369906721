import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApiService } from "./api/api.service";
import { Claim } from "./accounts/accounts.model";
import { ConfigService } from "./config/config.service";

@Injectable({
  providedIn: "root",
})
export class ChangeEmailService {
  constructor(
    private api: ApiService,
    private config: ConfigService
  ) {}

  sendChangeEmailRequest(
    userId: string,
    synlabId: string,
    payload: Claim[]
  ): Observable<{ success: boolean; message: string }> {
    const endpoint: string = `/api/profile/send-verification-email/${userId}/${synlabId}`;
    return this.api.postAccounts(endpoint, payload);
  }

  resendChangeEmailRequest(
    userId: string,
    synlabId: string,
    payload: Claim[]
  ): Observable<{ success: boolean; message: string }> {
    const endpoint: string = `/api/profile/resend-verification-email/${userId}/${synlabId}`;
    return this.api.postAccounts(endpoint, payload);
  }

  cancelChangeEmailRequest(userId: string): Observable<{ success: boolean; message: string }> {
    const endpoint: string = `/api/profile/cancel-change-email-request/${userId}`;
    return this.api.postAccounts(endpoint);
  }

  checkPendingChangeEmailRequest(userId: string): Observable<{ newEmail: string }> {
    const endpoint: string = this.config?.baseAccountUrl + `/api/profile/get-pending-change-email-request/${userId}`;
    return this.api.getHttp(endpoint);
  }

  verifyEmailLink(userId: string, seqId: string) {
    const endpoint: string = `/api/profile/validate-change-email-link/${userId}/${seqId}`;
    return this.api.postAccounts(endpoint);
  }
}
