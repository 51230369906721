import { HttpClient } from "@angular/common/http";
import { ConfigService } from "src/app/services/config/config.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private transloco: TranslocoService
  ) {}

  get<T = any>(endpoint: string, params?: any, options: Object = {}): Observable<T> {
    return this.http.get<T>(`${this.config.baseAuthUrl}${endpoint}`, {
      params: params ? params : {},
      headers: this.headers(),
    });
  }

  getProfile<T = any>(endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(`${this.config.baseProfileApiUrl}${endpoint}`, {
      params: params ? params : {},
    });
  }
  getHttp<T = any>(endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(`${endpoint}`, {
      params: params ? params : {},
    });
  }
  post<T = any>(endpoint: string, body: Object = {}, options: Object = {}): Observable<T> {
    const opts = {
      ...options,
      headers: this.headers(),
    };
    return this.http.post<T>(`${this.config.baseAuthUrl}${endpoint}`, body, opts);
  }

  postAccounts<T = any>(endpoint: string, body: Object = {}, options: Object = {}): Observable<T> {
    return this.http.post<T>(`${this.config.baseAccountUrl}${endpoint}`, body, options);
  }

  put<T = any>(endpoint: string, body: Object = {}, options: Object = {}): Observable<T> {
    const opts = {
      ...options,
      headers: this.headers(),
    };
    return this.http.put<T>(`${this.config.baseAuthUrl}${endpoint}`, body, opts);
  }

  delete(endpoint: string): Observable<any> {
    return this.http.delete(`${this.config.baseAuthUrl}${endpoint}`);
  }

  public formatAcceptLanguage(activeLang: string): string {
    let language = activeLang;
    switch (activeLang) {
      case "hu":
        language = "hu-HU";
        break;
      case "et":
        language = "et-EE";
        break;
      case "ru":
        language = "ru-RU";
        break;
      case "en":
      case "en-us":
      case "en-gb":
        language = "en-GB";
        break;
    }
    return language;
  }

  public headers() {
    let activeLang = this.transloco.getActiveLang().toLowerCase();
    return {
      "accept-language": this.formatAcceptLanguage(activeLang),
    };
  }
}
