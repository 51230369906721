import { Injectable } from "@angular/core";
import { MedioRedirectComponent } from "./medio-redirect.component";
import { ApiService } from "src/app/services/api/api.service";
import { Observable } from "rxjs";
import { take, tap, switchMap, map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { LoggerService } from "src/app/services/logger.service";
import { ConfigService } from "src/app/services/config/config.service";

@Injectable({
  providedIn: "root",
})
export class MedioService {
  constructor(
    private readonly api: ApiService,
    private readonly route: ActivatedRoute,
    private readonly logr: LoggerService,
    private readonly config: ConfigService
  ) {}

  public redirect(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const medioUrl: string = atob(urlParams.get("uid") ?? "");

    if (medioUrl) {
      window.location.replace(medioUrl);
    }
  }
}
