import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  providers: [{ provide: Window, useValue: window }],
})
export class ForgotPasswordModule {}
