import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { finalize, tap, catchError, delay, take } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { ApiService } from "./../../../services/api/api.service";
import { ConfigService } from "src/app/services/config/config.service";
import { Store, select } from "@ngrx/store";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { EPassForm } from "src/app/models/password.model";
import { passwordValidation } from "src/app/modules/shared/regex-pattern";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  loading = false;
  isMatched = false;
  showSpin = true;
  showForm = false;
  regId = "";
  seqId = "";
  returnUrl = "";
  showError = false;
  submitted = false;
  passwordFormatInvalid: boolean = false;
  useNewNav$: Observable<boolean> = this.store.pipe(select(StsSelectors.useNewNav));
  isSHFYInstance$ = this.store.pipe(select(StsSelectors.isUkShfyInstance));

  passwordFormG = new UntypedFormGroup({
    authenticationProvider: new UntypedFormControl("local"),
    schemeProvider: new UntypedFormControl(""),
    newPassword: new UntypedFormControl("", [Validators.required, Validators.pattern(passwordValidation)]),
    confirmPassword: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private api: ApiService,
    private activeRoute: ActivatedRoute,
    private toast: ToastrService,
    @Inject(Window) private window,
    private translocoService: TranslocoService,
    private config: ConfigService,
    private store: Store
  ) {}

  ngOnInit() {
    this.passwordFormG.setValidators(this.checkPasswords);

    this.activeRoute.queryParams
      .pipe(
        take(1),
        tap((params) => {
          this.regId = params.id;
          this.seqId = params.seq;
          this.returnUrl = atob(params.returnUrl || "");

          this.loadClaims();
        })
      )
      .subscribe();
  }

  loadClaims() {
    this.api.get("/manage/accounts/verify_forgot_password", { id: this.regId, seq: this.seqId }).subscribe((d) => {
      this.showSpin = false;
      this.showForm = d;
    });
  }

  setAccountPassword() {
    const f = this.passwordFormG.getRawValue();

    this.loading = true;
    this.passwordFormatInvalid = false;
    this.api
      .put(`/manage/accounts/forgot_password`, {
        UserId: this.regId,
        NewPassword: f[EPassForm.CONFIRM_PASS],
        Sequence: this.seqId,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        tap(() => {
          this.toast.success(
            this.translocoService.translate("successfullyUpdatedPasswordText", {}, "forgotPassword"),
            this.translocoService.translate("successfullyUpdatedPasswordTitle", {}, "forgotPassword")
          );
          this.submitted = true;
        }),
        delay(2000),
        tap(() => {
          this.passwordFormG.reset();
          this.passwordFormG.markAsPristine();

          this.window.location.href = `${this.config._envConfig.BaseAuthUrl}/Account/Login`;
        }),
        catchError((err) => {
          if (
            err &&
            err.error &&
            typeof err.error.ErrorMessage === "string" &&
            err.error.ErrorMessage.indexOf("Incorrect Password Format") > -1
          ) {
            this.toast.error(this.translocoService.translate("incorrectPasswordFormat", {}, "security"));
            this.passwordFormatInvalid = true;
            return;
          }
          const errMessage =
            err && err.error && err.error.ErrorMessage
              ? err.error.ErrorMessage
              : this.translocoService.translate("errorUpdatingPasswordText", {}, "forgotPassword");
          this.toast.error(
            this.translocoService.translate("errorUpdatingPasswordText", {}, "forgotPassword"),
            this.translocoService.translate("errorOccuredTitle", {}, "general")
          );
          console.error(errMessage || err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  redirectToContactSynlab() {
    this.window.location.href = this.config._envConfig.BaseProfileUrl;
  }

  get EPassForm(): typeof EPassForm {
    return EPassForm;
  }

  public checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get(EPassForm.NEW_PASS).value;
    let confirmPass = group.get(EPassForm.CONFIRM_PASS).value;
    return pass === confirmPass || confirmPass == "" ? null : { [EPassForm.CONFIRM_PASS]: true };
  };
}
