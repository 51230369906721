import { CommonModule } from "@angular/common";
import { Component, Input, NgModule, ChangeDetectionStrategy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ACCOUNTS_LOCALE_STORAGE_KEY, PATIENT_WEB_LOCALE_STORAGE_KEY } from "../core/constants";
import { take } from "rxjs/operators";
import { lookupCountry, defaultCountryPerLang } from "../core/util/lookup-default-country.util";



@Component({
  selector: 'lib-locale-picker',
  templateUrl: './locale-picker.component.html',
  styleUrls: ['./locale-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LocalePickerComponent {

  @Input() useFlags: boolean = true;
  @Input() mode: 'row'|'dropdown' = 'row';

  availableLocale = this.translocoService.getAvailableLangs() as string[];
  activeLocale$ = this.translocoService.langChanges$;

  constructor(
    private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  changeLang(locale:string) {
    this.translocoService.setActiveLang(locale);
    sessionStorage?.setItem(ACCOUNTS_LOCALE_STORAGE_KEY, locale);
    localStorage?.setItem(PATIENT_WEB_LOCALE_STORAGE_KEY, locale);

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((p) => {
      for (const i in p) {
        if (i.toLowerCase() == 'langcode')
          this.updateQueryParam(locale)
      }
    });
  }

  lookupCountry(locale:string) {
    return lookupCountry(locale, defaultCountryPerLang);
  }

  updateQueryParam(locale) {
    this.router.navigate([],{
      relativeTo: this.activatedRoute,
      queryParams: { langCode: locale },
      queryParamsHandling: 'merge'
    });
  }

  public getDisplayLocale(locale: string) {
    const [part1] = locale.split('-');
    return part1;
  }

}

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule, MatButtonModule, MatMenuModule, MatIconModule],
  declarations: [LocalePickerComponent],
  exports: [LocalePickerComponent]
})
export class LocalePickerModule {}
