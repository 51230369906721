import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MonitoringService {
  appInsights: ApplicationInsights;
  envSetting: any;

  constructor(private httpClient: HttpClient) {
    this.httpClient.get<any>("assets/env.json").subscribe((data) => {
      this.envSetting = data;
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.envSetting.ApplicationInsights.InstrumentationKey,
          appId: this.envSetting.ApplicationMonitoring.ApplicationName,
          enableAutoRouteTracking: true, // option to log all route changes
        },
      });

      console.log(this.envSetting);

      if (this.envSetting.ApplicationMonitoring.Enabled) {
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
      }
    });
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
