import { FormConfigInterface } from "./interfaces/form-config.interface";

export const ES_TC_CONFIG: FormConfigInterface = {
  completeProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Hide: () => false },
    { Name: "email", Required: () => true, Type: "text", Order: 3, Hide: () => false, Readonly: true },
    { Name: "phone", Required: () => true, Type: "phone", Order: 8, Hide: () => false },
    { Name: "locale", Required: () => true, Type: "locale-picker", Order: 4, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 5, Hide: () => false },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 6, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 7, Hide: () => false },
  ],
  completeProfileExpert: [
    { Name: "medical_seal_number", Required: () => false, Type: "text", Order: 5.1, Hide: () => false },
    {
      Name: "title",
      Required: () => true,
      Type: "select-single",
      Order: 0.1,
      Hide: () => false,
      Options: {
        FetchFromUrl: false,
        Url: null,
        Items: [
          { Value: "Dr.", Label: "Dr." },
          { Value: "Prof.", Label: "Prof." },
          { Value: "Prof. Dr.", Label: "Prof. Dr." },
        ],
      },
    },
  ],
  completeProfileExpertAdditionalInfo: [
    {
      Name: "institutions",
      Required: () => true,
      Type: "institutions-selector",
      Order: 9,
      Hide: () => false,
      Options: {
        AddNewItemEnabled: false,
        FetchFromUrl: true,
        Url: "/v2/manage/accounts/expert-dropdown-items-with-paging?&category=Institutions&page=1&pageSize=1000",
        Items: [],
      },
    },
  ],
  personalInfo: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 0, Hide: () => false },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 0, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 0, Hide: () => false },
  ],
  contact: [
    { Name: "country_address", Required: () => false, Type: "country-picker", Order: 0, Hide: () => false },
    { Name: "city", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "zipcode", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "address", Required: () => false, Type: "text", Order: 0, Hide: () => false },
    { Name: "phone", Required: () => true, Type: "phone", Order: 0, Hide: () => false },
    { Name: "email", Required: () => true, Type: "text", Order: 0, Hide: () => false },
  ],
  expertPersonalInfo: [
    { Name: "title", Required: () => true, Type: "salutation-selector", Hide: () => false },
    { Name: "given_name", Required: () => true, Type: "text", Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Hide: () => false },
    { Name: "medical_seal_number", Required: () => false, Type: "text", Hide: () => false },
  ],
  expertContact: [
    { Name: "phone", Required: () => true, Type: "phone", Hide: () => false },
    { Name: "email", Required: () => true, Type: "text", Hide: () => false },
  ],
};
