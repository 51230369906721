import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, merge, ReplaySubject, Subscription } from "rxjs";
import { AppState } from "src/app/@store/reducers";
import { RegistrationSelectors } from "src/app/@store/selectors/registration.selectors";
import parsePhoneNumber from "libphonenumber-js";
import { filter, take, tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { RegisterActions } from "src/app/@store/actions/registration.actions";
import * as RegisterProfileActions from "src/app/@store/actions/register-profile.actions";
import {
  ISetMFA,
  ISetMFAVerify,
  ISetPhoneNumber,
  IVerifyPhoneNumber,
} from "src/app/@store/reducers/registration.reducer";
import { Actions, ofType } from "@ngrx/effects";
import { TranslocoService } from "@ngneat/transloco";
import { generalScopeKey, twoFactorAuthenticatorScopeKey } from "src/app/services/i18n/i18n.service";
import { ToastrService } from "ngx-toastr";
import { MyAccountService } from "src/app/pages/index/pages/my-account/my-account.service";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimsToObject } from "src/app/utils/claims-to-object";
import { claimTypes } from "src/app/appsettings";

export enum EAuthenticationType {
  AUTHENTICATOR = "Authenticator",
  PHONE = "Phone",
}
const tkey1 = "twoFactorAuthenticator";

@Component({
  selector: "app-register-mfa-sms",
  templateUrl: "./register-mfa-sms.component.html",
  styleUrls: ["./register-mfa-sms.component.scss"],
})
export class RegisterMfaSmsComponent implements OnInit, OnDestroy {
  tkey1 = tkey1;
  phoneNumber$ = this._store.pipe(select(RegistrationSelectors.getRegistrationPhoneNumber));
  editPhoneLoading$ = this._store.pipe(select(RegistrationSelectors.getEditPhoneLoading));
  getSMSMFACodeLoading$ = this._store.pipe(select(RegistrationSelectors.getSMSMFACodeLoading));

  editPhoneToggle$ = new ReplaySubject(1);
  smsCodePhoneUpdateToggle$ = new ReplaySubject(1);
  smsCodeEnableMFAToggle$ = new ReplaySubject(1);

  public form: FormGroup;
  phoneNumberFC = new UntypedFormControl(undefined, [Validators.required]);
  smsCodeFC = new UntypedFormControl(undefined, [Validators.required]);
  smsCodeEnableMfaFC = new UntypedFormControl(undefined, [Validators.required]);

  enableSMSLoading: boolean = false;
  countryISO: string = undefined;
  phoneNumber: string = undefined;
  private _subs = new Subscription();
  public isValid: boolean;

  constructor(
    private _store: Store<AppState>,
    private _actRoute: ActivatedRoute,
    private _actions$: Actions,
    private _transloco: TranslocoService,
    private _toastr: ToastrService,
    private formBuilder: FormBuilder,
    private myAccountSvc: MyAccountService
  ) {}

  public validatePhoneFormGroupValues(event) {
    const values = event.value.phone;
    this.countryISO = values.countryCode;
    this.phoneNumber = values.e164Number;
    this.phoneNumberFC = event.get("phone");
    this.isValid = this.myAccountSvc.phoneValidator.isValidNumber(event.get("phone")?.getRawValue());
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      phone: ["", [Validators.required]],
      country_phone: ["", [Validators.required]],
    });
    this._subs.add(
      this.phoneNumber$
        .pipe(
          filter((n) => n),
          tap((phone) => {
            if (phone) {
              const phoneTemp = parsePhoneNumber(`${phone}`);
              if (phoneTemp) {
                this.form.get("phone").setValue(phoneTemp?.nationalNumber);
                this.form.get("country_phone").setValue(phoneTemp?.country);
              }
            }
          })
        )
        .subscribe()
    );
    // reset view
    this.resetView();

    if ("userid" in this._actRoute.snapshot.queryParams) {
      const { userid } = this._actRoute.snapshot.queryParams;
      this._subs.add(this._store.dispatch(RegisterActions.loadExistingUserClaims({ userid: userid })));
    }

    // toggle view for entering code from sms
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.updatePhoneNumberSuccess)).subscribe(({ phone }) => {
        this.smsCodePhoneUpdateToggle$.next(true);

        const mess = this._transloco.translate(
          "verificationCodeSentText",
          { phoneNumber: phone },
          twoFactorAuthenticatorScopeKey
        );
        this._toastr.success(mess);

        // set focus
        setTimeout(() => {
          document.getElementById("smsCodeFC")?.focus();
        }, 100);
      })
    );

    // reset phone update formcontrols
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.verifyPhoneNumberSuccess)).subscribe(({ success }) => {
        if (success) {
          this.resetView();
          this.smsCodeFC.reset();

          // reload claims
          const params = this._actRoute.snapshot.queryParams;

          const { id, sequenceId, seqId } = params;
          const sequence_id: string = sequenceId || seqId;
          if (id && sequence_id) {
            this._store.dispatch(RegisterActions.loadRegistrationClaims({ id, seqId: sequence_id }));
          } else {
            combineLatest([
              this._store.select(StsSelectors.isCzechRepublic),
              this._store.select('sts')
            ])
            .pipe(take(1)).subscribe(([isCz, { claims }]) => {
              if (isCz) {
                const objClaims: any = claimsToObject(claims);
                objClaims[claimTypes.TWO_FACTOR_ENABLED] = 'True';
                this._store.dispatch(RegisterActions.loadRegistrationClaimSuccess({ claims: objClaims }));
              }
            });
          }
        }
      })
    );

    // hide loading indicator for sms enable button
    this._subs.add(
      merge(
        this._actions$.pipe(ofType(RegisterActions.enableMFASuccess)),
        this._actions$.pipe(ofType(RegisterActions.enableMFAFailed))
      ).subscribe((n) => (this.enableSMSLoading = false))
    );

    // show error message
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.enableMFAFailed)).subscribe(({ error }) => {
        this._toastr.error(
          error.error.ErrorMessage,
          this._transloco.translate("errorOccuredTitle", {}, generalScopeKey)
        );
      })
    );

    // display code input for enabling MFA - sms
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.enableMFASuccess)).subscribe(({ data, authType }) => {
        if (authType == EAuthenticationType.PHONE.toLowerCase()) {
          this.smsCodeEnableMFAToggle$.next(true);
          const m = this._transloco.translate(
            "verificationCodeSentText",
            { phoneNumber: this.phoneNumber },
            twoFactorAuthenticatorScopeKey
          );
          this._toastr.success(m);

          // set focus
          setTimeout(() => {
            document.getElementById("smsCodeEnableMfaFC")?.focus();
          }, 100);
        }
      })
    );

    // re-fetch registration claims
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.enableMFAVerifySuccess)).subscribe(({ data }) => {
        if (data.result && data.type.toLowerCase() == EAuthenticationType.PHONE.toLowerCase()) {
          const { id, sequenceId, seqId } = this._actRoute.snapshot.queryParams;
          const sequence_id: string = sequenceId || seqId;
          if (id && sequence_id) {
            this._store.dispatch(RegisterActions.loadRegistrationClaims({ id, seqId: sequence_id }));
          } else {
            combineLatest([
              this._store.select(StsSelectors.isCzechRepublic),
              this._store.select('sts')
            ])
            .pipe(take(1)).subscribe(([isCz, { claims }]) => {
              if (isCz) {
                const objClaims: any = claimsToObject(claims);
                objClaims[claimTypes.TWO_FACTOR_ENABLED] = 'True';
                this._store.dispatch(RegisterActions.loadRegistrationClaimSuccess({ claims: objClaims }));
              }
            });
          }
        }
      })
    );

    // reset <SMS MFA UI> if "enable" button in Authenticator App is clicked.
    this._subs.add(
      this._actions$.pipe(ofType(RegisterActions.resetMFAInProgress)).subscribe(({ authType }) => {
        if (authType == EAuthenticationType.PHONE) {
          this.resetView();

          this.smsCodeFC.reset();
          this.smsCodeEnableMfaFC.reset();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  toggleEditSmsOnclick(value: boolean) {
    this.editPhoneToggle$.next(value);

    // reset auth app
    this._store.dispatch(
      RegisterActions.resetMFAInProgress({
        authType: EAuthenticationType.AUTHENTICATOR,
      })
    );
  }

  updatePhoneNumber() {
    this.smsCodeFC.reset();
    const p = this.phoneNumberFC.value;
    const { id, userId } = this._actRoute.snapshot.queryParams;
    const encodedId = id || userId;
    const payload: ISetPhoneNumber = {
      code: encodedId,
      phone: p?.e164Number?.replace("+", ""),
      countryPhone: p?.countryCode,
    };
    this._store.dispatch(RegisterActions.updatePhoneNumber({ payload }));
  }

  verifyPhone() {
    const code = this.smsCodeFC.value;
    const phone = this.phoneNumberFC.value;
    const { id, userId } = this._actRoute.snapshot.queryParams;
    const encodedId = id || userId;
    const payload: IVerifyPhoneNumber = {
      code: encodedId,
      phone: phone?.e164Number?.replace("+", ""),
      token: code,
    };

    this._store.dispatch(RegisterActions.verifyPhoneNumber({ payload }));
  }

  resetView() {
    this.smsCodePhoneUpdateToggle$.next(false);
    this.editPhoneToggle$.next(false);
    this.smsCodeEnableMFAToggle$.next(false);
  }

  enableMFA(authType: EAuthenticationType) {
    const t = <any>authType?.toLowerCase();
    const { id, userId } = this._actRoute.snapshot.queryParams;
    const encodedId = id || userId;
    const payload: ISetMFA = {
      code: encodedId ? encodedId : btoa(this._actRoute.snapshot.queryParams["userid"]),
      twoFactorEnabled: true,
      twoFactorProvider: t,
    };

    this.enableSMSLoading = true;
    this._store.dispatch(RegisterActions.enableMFA({ payload }));

    this._store.dispatch(
      RegisterActions.resetMFAInProgress({
        authType: EAuthenticationType.AUTHENTICATOR,
      })
    );
  }

  enableMFAVerify(authType: EAuthenticationType) {
    const { id, userId } = this._actRoute.snapshot.queryParams;
    const encodedId = id || userId;
    const payload: ISetMFAVerify = {
      code: encodedId ? encodedId : btoa(this._actRoute.snapshot.queryParams["userid"]),
      twoFactorEnabled: true,
      tokenProvider: <any>authType,
      token: this.smsCodeEnableMfaFC.value,
    };

    this._store.dispatch(RegisterActions.enableMFAVerify({ payload }));
  }
}
