import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserTypeGuard {
  constructor(private auth: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this will wait for 'auth.profileInfo' to be set in auth.service before loading page
    return this.auth.userInfo$.pipe(
      filter((i) => !!i),
      map((claims) => !!claims)
    );
  }
}
