import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { emailPattern } from "src/app/modules/shared/regex-pattern";
import { ApiService } from "src/app/services/api/api.service";
import { ConfigService } from "src/app/services/config/config.service";

@Component({
  selector: "app-dxp-send-forgot-password",
  templateUrl: "./dxp-send-forgot-password.component.html",
  styleUrls: ["./dxp-send-forgot-password.component.scss"],
})
export class DxpSendForgotPasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public showMessage = false;
  public loading = false;
  public submitted = true;

  private returnUrl = "";
  private readonly emailPattern = new RegExp(emailPattern);

  constructor(
    private api: ApiService,
    private config: ConfigService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }

  public get email() {
    return this.form.get("email");
  }

  public onClear() {
    this.email.reset();
  }

  public onCancel() {
    window.location.href = `${this.config.baseAuthUrl}/Account/Login${window.location.search}`;
  }

  public formatSuccessMessage(message: string) {
    return message.replace("[address]", this.email.value);
  }

  public onSubmit() {
    this.loading = true;
    const email = encodeURIComponent(this.email.value);
    this.api
      .post(`/account/sendforgotpassword?returnUrl=${encodeURIComponent(this.returnUrl)}&email=${email}`)
      .subscribe(
        () => {
          this.showMessage = true;
          this.loading = false;
          this.submitted = true;
        },
        (e) => {
          this.loading = false;
        }
      );
  }
}
