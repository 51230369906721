import { Injectable } from '@angular/core';

function _window() {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  constructor() { }
  get nativeWindow() {
    return _window();
  }
}
