export interface Country {
  name: string;
  countryCode: string;
  flag: string;
}

export const Countries: Country[] = [
  {
    name: "English (UK)",
    countryCode: "en-GB",
    flag: "/assets/images/flag-icons/uk.png",
  },
  {
    name: "Hungarian",
    countryCode: "hu-HU",
    flag: "/assets/images/flag-icons/hu.png",
  },
  {
    name: "Portuguese (Portugal)",
    countryCode: "pt",
    flag: "/assets/images/flag-icons/pt.png",
  },
  {
    name: "Spanish (Spain)",
    countryCode: "es-ES",
    flag: "/assets/images/flag-icons/es.png",
  },
  {
    name: "French",
    countryCode: "fr-FR",
    flag: "/assets/images/flag-icons/fr.png",
  },
  {
    name: "French (Swiss)",
    countryCode: "fr-CH",
    flag: "/assets/images/flag-icons/ch.png",
  },
  {
    name: "German",
    countryCode: "de",
    flag: "/assets/images/flag-icons/de.png",
  },
  {
    name: "German (Swiss)",
    countryCode: "de-CH",
    flag: "/assets/images/flag-icons/ch.png",
  },
  {
    name: "Italian",
    countryCode: "it",
    flag: "/assets/images/flag-icons/it.png",
  },
  {
    name: "Italian (Swiss)",
    countryCode: "it-CH",
    flag: "/assets/images/flag-icons/ch.png",
  },
  // {
  //   name: "Arabic",
  //   countryCode: "ar-AE",
  //   flag: "/assets/images/flag-icons/ar-AE.png",
  // },
  // {
  //   name: "Belarusian",
  //   countryCode: "be-BY",
  //   flag: "/assets/images/flag-icons/be-BY.png",
  // },
  // {
  //   name: "Croatian",
  //   countryCode: "hr-HR",
  //   flag: "/assets/images/flag-icons/hr-HR.png",
  // },
  // {
  //   name: "Danish",
  //   countryCode: "da-DK",
  //   flag: "/assets/images/flag-icons/da-DK.png",
  // },
  // {
  //   name: "Dutch",
  //   countryCode: "nl-BE",
  //   flag: "/assets/images/flag-icons/nl-BE.png",
  // },
  {
    name: "Estonian",
    countryCode: "et",
    flag: "/assets/images/flag-icons/et.png",
  },
  {
    name: "Greek",
    countryCode: "el",
    flag: "/assets/images/flag-icons/el.png",
  },
  {
    name: "Lithuanian",
    countryCode: "lt-LT",
    flag: "/assets/images/flag-icons/lt-LT.png",
  },
  // {
  //   name: "Macedonian",
  //   countryCode: "mk-MK",
  //   flag: "/assets/images/flag-icons/mk-MK.png",
  // },
  // {
  //   name: "Polish (Poland)",
  //   countryCode: "pl-PL",
  //   flag: "/assets/images/flag-icons/pl-PL.png",
  // },
  // {
  //   name: "Romanian (Romania)",
  //   countryCode: "ro-RO",
  //   flag: "/assets/images/flag-icons/ro-RO.png",
  // },
  {
    name: "Russian",
    countryCode: "ru",
    flag: "/assets/images/flag-icons/ru.png",
  },
  // {
  //   name: "Slovenian",
  //   countryCode: "sl-SI",
  //   flag: "/assets/images/flag-icons/sl-SI.png",
  // },
  {
    name: "Turkish (Turkey)",
    countryCode: "tr-TR",
    flag: "/assets/images/flag-icons/tr-TR.png",
  },
  // {
  //   name: "Ukrainian",
  //   countryCode: "uk-UA",
  //   flag: "/assets/images/flag-icons/uk-UA.png",
  // },
];
