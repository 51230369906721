import { Injectable } from "@angular/core";
import { LegalDocumentsService } from "./legal-documents.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  searchLegalDocumentsAction,
  searchLegalDocumentsSuccessAction,
  searchLegalDocumentsVersionsAction,
  searchLegalDocumentsVersionsSuccessAction,
} from "./legal-documents.actions";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class LegalDocumentsEffects {
  public searchLegalDocuments$ = this.createSearchLegalDocumentsEffect();
  public searchLegalDocumentsVersions$ = this.createSearchLegalDocumentsVersionsEffect();

  constructor(
    private readonly actions$: Actions,
    private readonly toastrService: ToastrService,
    private readonly legalDocumentsService: LegalDocumentsService
  ) {
    this.catchError = this.catchError.bind(this);
  }

  private createSearchLegalDocumentsVersionsEffect() {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(searchLegalDocumentsVersionsAction),
        exhaustMap(({ userId, docType }) =>
          this.legalDocumentsService.searchLegalDocumentsVersions(userId, docType).pipe(
            map((versions) => searchLegalDocumentsVersionsSuccessAction({ versions })),
            catchError(this.catchError)
          )
        )
      )
    );
  }

  private createSearchLegalDocumentsEffect() {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(searchLegalDocumentsAction),
        exhaustMap(({ userId }) =>
          this.legalDocumentsService.searchLegalDocuments(userId).pipe(
            map((legalDocuments) => searchLegalDocumentsSuccessAction({ legalDocuments })),
            catchError(this.catchError)
          )
        )
      )
    );
  }

  private catchError(error: any) {
    const errorMessage = error?.error?.message || "Something went wrong.";
    this.toastrService.error(errorMessage, "Error", { timeOut: 5000 });
    return EMPTY;
  }
}
