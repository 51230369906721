import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "src/app/@store/reducers";
import { HeaderSelectors } from "src/app/@store/selectors/header.selectors";
import { HeaderVisibilityService as LibHeaderVisibilityService } from "src/app/plasma-ui-common/synlab-access-ui-components/core/header-visibility/header-visibility.service";

@Injectable({ providedIn: "root" })
export class HeaderVisibilityService implements LibHeaderVisibilityService {
  visible$: Observable<boolean> = this._store.pipe(select(HeaderSelectors.getVisibility));
  constructor(private _store: Store<AppState>) {}
}
