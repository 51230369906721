import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { Consents, ConsentsPayload } from "src/app/pages/index/pages/notification-settings/notification.model";
import { AccountsService } from "src/app/services/accounts/accounts.service";
import { ProfileActions } from "../actions/profile.actions";
import { ProfileSelectors } from "../selectors/profile.selectors";
import { StsSelectors } from "../selectors/sts.selectors";
import { registerProfile } from "../selectors/register-profile.selectors";
import { claimTypes } from "src/app/appsettings";

const residenceCountryCodeKey = "residenceCountryCode";
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    let x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      storage &&
      storage.length !== 0
    );
  }
}

function saveToStorage(key: string, value: string) {
  if (storageAvailable("sessionStorage")) {
    sessionStorage.setItem(key, value);
  }
}

function getFromStorage(key: string): string {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.getItem(key);
  } else {
    return undefined;
  }
}

function removeFromStorage(key: string) {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.removeItem(key);
  }
}

function stringToBoolean(v: boolean | string): boolean {
  if (typeof v == "boolean") {
    return v;
  }
  return v && v.toLocaleLowerCase() === "true";
}

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private acc: AccountsService
    // private auth: AuthService
  ) {
    this.store.dispatch(ProfileActions.restoreResidence({ countryCode: getFromStorage(residenceCountryCodeKey) }));
  }

  saveResidenceCountryCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.setResidence),
        tap(({ countryCode }) => {
          saveToStorage(residenceCountryCodeKey, countryCode);
        }),
        catchError((error, caught) => {
          console.error("Failed to save code", error);
          return caught;
        })
      ),
    { dispatch: false }
  );

  removeStoredResidence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.removeStoredResidence),
        tap(() => removeFromStorage(residenceCountryCodeKey)),
        catchError((error, caught) => {
          console.error("Failed to clear code", error);
          return caught;
        })
      ),
    { dispatch: false }
  );

  getProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.getProfile),
        withLatestFrom(this.store.pipe(select(StsSelectors.getUserInfo))),
        switchMap(([action, user]) => {
          if (user) {
            return this.acc.get(user.userId);
          } else {
            return of([]);
          }
        }),
        map((x) => {
          const data = x.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {});
          this.store.dispatch(ProfileActions.getProfileSuccess({ data }));
        }),
        catchError((error, caught) => {
          console.error("Error: ", error);
          return caught;
        })
      ),
    { dispatch: false }
  );

  getConsents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.getConsent),
        switchMap((action) => this.acc.getConsent(action.synlabId, action.categoryName)),
        withLatestFrom(this.store.pipe(select(ProfileSelectors.getConsentsPayload))),
        map(([consents, payload]: [Consents[], ConsentsPayload[]]) => {
          if (consents) {
            return consents?.map((d) => {
              // check if there's existing payload, it means the user have already interacted and we only need translation locales
              let x = payload?.find((p) => p.ConsentId == d.consentId).Allowed;
              return { ...d, allowed: x === undefined ? (d.allowed ? stringToBoolean(d.allowed) : false) : x };
            });
          }
        }),
        tap((c) => {
          this.store.dispatch(ProfileActions.getConsentSuccess({ data: c }));
        }),
        catchError((error, caught) => {
          this.store.dispatch(ProfileActions.getConsentFailed({ error: error }));
          console.error("Error: ", error);
          return caught;
        })
      ),
    { dispatch: false }
  );

  getConsentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.getConsentSuccess),
        withLatestFrom(this.store.select(StsSelectors.getWhiteLabelName), this.store.select(registerProfile)),
        switchMap(([action, tenant, claims]) => {
          if (tenant === "fi") {
            const emailAddress: string = claims?.find((c) => c.claimType == claimTypes.EMAIL)?.claimValue;
            if (emailAddress) {
              return this.acc.checkIfMarketingConsentIsChecked(emailAddress);
            }
          }
          return of(false);
        }),
        tap((c) => this.store.dispatch(ProfileActions.getMarketingConsentsCheckedSuccess({ value: c }))),
        catchError((error, caught) => {
          console.error("Error: ", error);
          return caught;
        })
      ),
    { dispatch: false }
  );
}
