import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { AppState } from "./@store/reducers";
import { AuthService } from "./services/auth/auth.service";
import { ConfigService } from "./services/config/config.service";
import { LoggerService } from "./services/logger.service";

@Injectable({
  providedIn: "root",
})
export class AppCommonServices {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public config: ConfigService,
    public transloco: TranslocoService,
    public logger: LoggerService,
    public toastr: ToastrService,
    public auth: AuthService
  ) {}
}
