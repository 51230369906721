import { createSelector } from "@ngrx/store";
import { claimValues, userTypes } from "src/app/appsettings";
import { AppState } from "../reducers";
import * as RegistrationReducer from "../reducers/registration.reducer";

const State = (state: AppState) => state[RegistrationReducer.registrationFeatureKey];

const getRegisterClaimUserId = createSelector(State, ({ claims }) => claims?.userId);
const setPasswordInProgress = createSelector(State, (state) => state.setPasswordInProgress);
const getTaraRegistrationEmail = createSelector(State, (state) => state.activationEmail);
const getResendActivationLoading = createSelector(State, (state) => state.resendActivationLoading);
const getIsTaraUserActivated = createSelector(State, (state) => state.activated);
const getRegistrationPhoneNumber = createSelector(State, (state) => state.claims?.phone);
const getEditPhoneLoading = createSelector(State, (state) => state.editPhoneLoading);
const getTwoFactorEnabledClaim = createSelector(
  State,
  (state) => state.claims?.two_factor_enabled?.toLowerCase() == claimValues.TRUE
);
const getLoadClaimsLoading = createSelector(State, (state) => state.getClaimsLoading);
const getSMSMFACodeLoading = createSelector(State, (state) => state.smsCodeMFALoading);

const getAuthenticatorUri = createSelector(State, (state) => state.authAppData?.authenticatorUri);
const getAuthenticatorAppCode = createSelector(State, (state) => state.authAppData?.code);
const getPrivacyAccepted = createSelector(State, (state) => state.privacyPolicyAccepted);
const getRegistrationUserType = createSelector(State, (state) => state.claims.user_type);
const getRegistrationIsPatient = createSelector(
  State,
  (state) => state.claims?.user_type?.toLowerCase() == userTypes.SYNLAB_ACCESS_USER
);

export const RegistrationSelectors = {
  getRegistrationIsPatient,
  getRegistrationUserType,
  getPrivacyAccepted,
  getAuthenticatorUri,
  getAuthenticatorAppCode,
  getLoadClaimsLoading,
  getTwoFactorEnabledClaim,
  getEditPhoneLoading,
  getRegisterClaimUserId,
  setPasswordInProgress,
  getTaraRegistrationEmail,
  getResendActivationLoading,
  getIsTaraUserActivated,
  getRegistrationPhoneNumber,
  getSMSMFACodeLoading,
};
