import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TermsOfUseMobileComponent } from "./terms-of-use-mobile.component";
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  declarations: [TermsOfUseMobileComponent],
  imports: [CommonModule, SharedModule],
  exports: [TermsOfUseMobileComponent],
})
export class TermsOfUseMobileModule {}
