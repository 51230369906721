import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthSessionTransferService } from "src/app/services/auth/auth-session-transfer.service";
import { sessionTransferKey } from "src/app/services/i18n/i18n.service";
import { ToastrService } from "ngx-toastr";
import { TranslocoService } from "@ngneat/transloco";
import { SessionTransferError } from "src/app/models/sessionTransfer.model";
@Component({
  selector: "app-init-session",
  template: "<app-loader></app-loader>",
  styleUrls: ["./init-session.component.scss"],
})
export class InitSessionComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionTransferService: AuthSessionTransferService,
    private translocoService: TranslocoService,
    private readonly toastr: ToastrService
  ) {
    toastr.toastrConfig.disableTimeOut = true;
  }

  ngOnInit(): void {
    const queryArgs = this.getQueryParams();
    if (queryArgs) {
      this.sessionTransferService.getSessionTransfer$(queryArgs).subscribe(
        (res) => {
          localStorage.setItem(`session-transfer`, JSON.stringify(res));
          this.router.navigate(["/account/profile"]);
        },
        (error: unknown) => {
          if (error instanceof SessionTransferError) {
            this.toastr.error(
              `${this.translocoService.translate("loginFailed", {}, sessionTransferKey)} - ${error.errorCode}`
            );
          }
        }
      );
    } else {
      this.toastr.error(this.translocoService.translate("loginFailed", {}, sessionTransferKey));
    }
  }

  private replaceSpaceWithPlus(text: string): string {
    return text.replace(" ", "+");
  }

  private getQueryParams(): { hashKey: string; sessionId: string } | undefined {
    const hashKey: string = this.route.snapshot.queryParamMap.get("hashKey");
    const sessionId: string = this.route.snapshot.queryParamMap.get("sessionId");

    if (hashKey && sessionId) {
      return {
        hashKey: this.replaceSpaceWithPlus(hashKey),
        sessionId: this.replaceSpaceWithPlus(sessionId),
      };
    } else {
      console.error("HashKey and/or sessionId were missing.");
      return undefined;
    }
  }
}
