import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { AccountsService } from "../accounts/accounts.service";
import moment from "moment";
import { LocalStorageKeys } from "src/app/models/storage.model";
import sessionStorage from "sessionstorage";
import { formatLocale } from "src/app/plasma-ui-common/utils/format-locale";

/** Key in translation json for `general` scope. */
export const generalScopeKey = "general";
/** Key in translation json for `header` scope. */
export const headerScopeKey = "header";
/** Key in translation json for `error` scope. */
export const errorScopeKey = "error";
/** Key in translation json for `myAccount` scope. */
export const myAccountScopeKey = "myAccount";
/** Key in translation json for `security` scope. */
export const securityScopeKey = "security";
/** Key in translation json for `twoFactorAuthenticator` scope. */
export const twoFactorAuthenticatorScopeKey = "twoFactorAuthenticator";
/** Key in translation json for `verifyProfile` scope. */
export const verifyProfileScopeKey = "verifyProfile";
/** Key in translation json for `completeProfile` scope. */
export const completeProfileScopeKey = "completeProfile";
/** Key in translation json for `registrationSecure` scope. */
export const registrationSecureScopeKey = "registrationSecure";
/** Key in translation json for `delete` scope. */
export const deleteScopeKey = "delete";
/** Key in translation json for `sessionTransfer` scope. */
export const sessionTransferKey = "initSession";
/** Key in translation json for `petProfile` scope. */
export const petProfileScopeKey = "petProfile";
/** storage key for lang code */
export const langCodeStorageKey = LocalStorageKeys.language;
/**
 * Service to manage i18n.
 */
@Injectable({ providedIn: "root" })
export class I18nService {
  activeLang$ = this.transloco.langChanges$;
  /** Browser locale. Used by moment. Should delete later if unnecessary. */
  browserLocale: string = this.getBrowserLanguages[0] || "en";
  country$ = this.accountsService.country$;
  constructor(
    private transloco: TranslocoService,
    private accountsService: AccountsService
  ) {}
  translateIfExists(key: string, scope: string | string[] = ""): string {
    if (typeof scope == "string") {
      const translated = this.transloco.translate(key, {}, scope);
      return `${scope}.${key}` == translated ? key : translated;
    } else {
      return this.translateIfExists(
        key,
        scope.find((s) => this.translateIfExists(key, s) != key)
      );
    }
  }
  getAvailableLanguages(): string[] {
    return this.transloco.getAvailableLangs() as string[];
  }

  setStoredLanguage(langCode: string) {
    sessionStorage && sessionStorage.setItem(langCodeStorageKey, langCode);
    this.transloco.setActiveLang(formatLocale(langCode));
  }
  /** detect browser locale and set moment global locale. */
  detectBrowserLocale() {
    const locale =
      this.getFromLocalStorage() || this.transloco.getDefaultLang() || this.getPreferredSupportedLanguage();
    this.setActiveLang(formatLocale(locale));
    moment.locale(locale);
  }
  /** sets active language. Does not save to storage.
   * use `setStoredLanguage` to save to storage.
   */
  private setActiveLang = this.transloco.setActiveLang.bind(this.transloco);

  private getFromLocalStorage() {
    return sessionStorage &&
      sessionStorage.getItem(langCodeStorageKey) &&
      this.getAvailableLanguages().indexOf(sessionStorage.getItem(langCodeStorageKey)) > -1
      ? sessionStorage.getItem(langCodeStorageKey)
      : undefined;
  }
  /** get browser user preferred language */
  private getBrowserLanguages(): string[] {
    return navigator.languages.map((x) => x) || [navigator.language] || [(navigator as any).userLanguage] || [];
  }
  /** determine preferred language between supported language and
   * browser settings. uses language fallbacks  */
  public getPreferredSupportedLanguage(
    preferredLanguages: string[] = this.getBrowserLanguages(),
    supportedLanguages: string[] = this.getAvailableLanguages()
  ): string | undefined {
    for (const pl of preferredLanguages) {
      const lang = supportedLanguages.find((sl) => pl === sl);
      if (lang !== undefined) {
        return lang;
      }
    }
    for (const pl of preferredLanguages) {
      const lang = supportedLanguages.find((sl) => this.getLangCode(sl) === this.getLangCode(pl));
      if (lang !== undefined) {
        return lang;
      }
    }
  }
  /** Extract language code from locale code. (ex: 'en' in 'en-US')
   * @param locale string to extract language code from.
   * @Note Naive language code parser.
   * Use in the future: https://www.npmjs.com/package/locale-code
   */
  private getLangCode(locale: string = ""): string {
    return (locale.trim().match(/^\w+/g) || []).shift();
  }

  public formatDate(isoDateTime: string, momentFormat: string = "LLL", outputAsUTCTimezone: boolean = false): string {
    if (typeof isoDateTime !== "string") {
      return "";
    }

    const mom = moment.utc(isoDateTime);
    if (mom.isValid()) {
      if (!outputAsUTCTimezone) {
        mom.local();
      }
      return mom.format(momentFormat);
    } else {
      return isoDateTime;
    }
  }

  /** Utility function to format time into localized format.
   * @param time 24 hour format of time
   * @param momentFormat optional parameter to specify the output format
   */
  public formatTime(time: string, momentFormat: string = "kk:mm") {
    const mom = moment(time, "kk:mm");
    return mom.format(momentFormat);
  }

  /** Get date format that can be used by moment */
  public getMomentDateFormatToken() {
    const defLanguage = this.transloco.getActiveLang();

    switch (defLanguage) {
      case "en":
        return "DD/MM/YYYY";
      case "pt":
      case "pt-PT":
        return "DD-MM-YYYY";
      default:
        return moment().localeData().longDateFormat("L");
    }
  }
}
