import { createAction, props } from "@ngrx/store";
import {
  IChangePhonePayload,
  IClaim,
  IClaimObject,
  IRequestQueryParams,
  ISet2FAPayload,
  IValidateDKMFAReset,
  IVerifyChangePhonePayload,
  IVerifySet2FAPayload,
} from "src/app/view/dk-mfa-reset/enum-dk-mfa";

const validateMFAResetLink = createAction(
  "[DkMfaReset] Validate MFA Reset Link",
  props<{ payload: IValidateDKMFAReset }>()
);

const validateMFAResetLinkSuccess = createAction(
  "[DkMfaReset] Validate MFA Reset Link Success",
  props<{ value: boolean }>()
);

const validateMFAResetLinkFailed = createAction("[DkMfaReset] Validate MFA Reset Link Failed", props<{ error: any }>());

const changePhone = createAction(
  "[DkMfaReset] Change Phone",
  props<{ params: IRequestQueryParams; body: IChangePhonePayload }>()
);
const changePhoneSuccess = createAction("[DkMfaReset] Change Phone Success", props<{ data: any }>());
const changePhoneFailed = createAction("[DkMfaReset] Change Phone Failed", props<{ error: any }>());

const verifyChangePhone = createAction(
  "[DkMfaReset] Verify Change Phone",
  props<{ params: IRequestQueryParams; body: IVerifyChangePhonePayload }>()
);
const verifyChangePhoneSuccess = createAction("[DkMfaReset] Verify Change Phone Success", props<{ data: any }>());
const verifyChangePhoneFailed = createAction("[DkMfaReset] Verify Change Phone Failed", props<{ error: any }>());

const set2FA = createAction("[DkMfaReset] Set 2FA", props<{ params: IRequestQueryParams; body: ISet2FAPayload }>());
const set2FASuccess = createAction(
  "[DkMfaReset] Set 2FA Success",
  props<{ data: any; mfaType?: string; phone?: string }>()
);
const set2FAFailed = createAction("[DkMfaReset] Set 2FA Failed", props<{ error: any }>());

const verifySet2FA = createAction(
  "[DkMfaReset] Verify Set 2FA",
  props<{ params: IRequestQueryParams; body: IVerifySet2FAPayload }>()
);
const verifySet2FASuccess = createAction("[DkMfaReset] Verify Set 2FA Success", props<{ data: any }>());
const verifySet2FAFailed = createAction("[DkMfaReset] Verify Set 2FA Failed", props<{ error: any }>());

const getMFAResetClaims = createAction("[DkMfaReset] Get MFA Reset Claims", props<{ params: IRequestQueryParams }>());
const getMFAResetClaimsSuccess = createAction(
  "[DkMfaReset] Get MFA Reset Claims Success",
  props<{ claims: IClaimObject }>()
);
const getMFAResetClaimsFailed = createAction("[DkMfaReset] Get MFA Reset Claims Failed", props<{ error: any }>());

export const DkMfaResetAction = {
  getMFAResetClaims,
  getMFAResetClaimsSuccess,
  getMFAResetClaimsFailed,
  verifySet2FA,
  verifySet2FASuccess,
  verifySet2FAFailed,
  set2FA,
  set2FASuccess,
  set2FAFailed,
  validateMFAResetLink,
  validateMFAResetLinkSuccess,
  validateMFAResetLinkFailed,
  changePhone,
  changePhoneSuccess,
  changePhoneFailed,
  verifyChangePhone,
  verifyChangePhoneSuccess,
  verifyChangePhoneFailed,
};
