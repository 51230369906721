import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { Profile, ProfileService, UsertypeService } from "src/app/plasma-ui-common/synlab-access-ui-components/core";
import { AuthService } from "src/app/services/auth/auth.service";

@Injectable()
export class HeaderUsertypeService implements UsertypeService, ProfileService {
  constructor(
    private _store: Store,
    private _auth: AuthService
  ) {}
  usertypes$: Observable<string[]> = this._auth.userInfo$.pipe(
    map((u) => (Array.isArray(u.user_type) ? u.user_type : [u.user_type]))
  );
  profile$: Observable<Profile> = this._store.pipe(select(StsSelectors.getUserHeader));
}
