export enum EReset {
  SMS = "sms",
  AUTH_APP = "auth-app",
}

export enum E2FAProvider {
  SMS = "Phone",
  AUTHENTICATOR = "Authenticator",
}

export interface IReset {
  reset: EReset;
  time: Date;
}

export interface IValidateDKMFAReset {
  id: string;
  seqId?: string;
  sequenceId?: string;
}

export interface IChangePhonePayload {
  countryPhone: string;
  phone: string;
}

export interface IVerifyChangePhonePayload {
  token: string;
  phone: string;
}

export interface IRequestQueryParams {
  id: string;
  seqId?: string;
  sequenceId?: string;
}

export interface ISet2FAPayload {
  code: string;
  twoFactorEnabled: boolean;
  twoFactorProvider: E2FAProvider;
}

export interface IVerifySet2FAPayload {
  twoFactorEnabled: boolean;
  tokenProvider: E2FAProvider;
  code: string;
  token: string;
}

export enum EResetMFAActivity {
  RESET_ISVALID = "mfa_reset_isvalid",
  RESET_CLAIMS = "mfa_reset_info",
  SET_2FA_SMS = "reset_set_two_factor_authentication_sms",
  SET_2FA_AUTH_APP = "reset_set_two_factor_authentication_auth_app",
  VERIFY_2FA = "verify_two_factor_authentication",
  CHANGE_PHONE = "reset_change_phone",
  VERIFY_PHONE = "reset_verify_phone",
}

export interface IClaim {
  claimType: string;
  claimValue: any;
}

export interface IClaimObject {
  [key: string]: string;
}
