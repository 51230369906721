<div data-test-id="activate-account-background"  class="background">
  <img data-test-id="activate-account-background-image"  src="/assets/dxp/img/background.png" alt="" srcset="" />
</div>

<div data-test-id="activate-account-wrapper"  class="wrapper" *transloco="let t; read: 'activateAccount'">
  <mat-spinner data-test-id="activate-account-spinner"  *ngIf="loading$ | async" diameter="70"></mat-spinner>
  <div data-test-id="activate-account-status-success"  *ngIf="success$ | async as success; else notSuccessful" class="status status-success">
    <ng-container  *ngIf="!(loading$ | async)">
      <h1 data-test-id="activate-account-success-h1" >{{ t("successH1") }}</h1>
      <p data-test-id="activate-account-success-p">{{ t("successParagraph") }}</p>
      <a data-test-id="activate-account-redirect-to-login-link" role="button" class="button" href="javascript:void(0);" (click)="redirectToLogin()">{{ t("loginButton") }}</a>
    </ng-container>
  </div>

  <ng-template #notSuccessful>
    <div data-test-id="activate-account-status-failure" 
      *ngIf="!(loading$ | async) && !isActiveUser && !isLinkExpired && !isDuplicateError"
      class="status status-failure"
    >
      <p data-test-id="activate-account-error-p">{{ t("errorH1") }}</p>
      <h1 data-test-id="activate-account-error-h1" >{{ (error$ | async) || t("errorParagraph") }}</h1>
    </div>
    <div data-test-id="activate-account-status-failure"  *ngIf="!(loading$ | async) && isLinkExpired" class="status status-failure">
      <h1 data-test-id="activate-account-failure-h1" >{{ t("shfyErrorH1") }}</h1>
      <p data-test-id="activate-account-failure-p">{{ t("shfyErrorParagraph") }}</p>
      <a data-test-id="activate-account-login-btn" role="button" class="button" href="javascript:void(0);" (click)="redirectToLogin()">{{
        t("shfyLoginButton")
      }}</a>
    </div>
    <div data-test-id="activate-account-status-failure"  *ngIf="!(loading$ | async) && isActiveUser" class="status status-failure">
      <h1 data-test-id="activate-account-active-user-error-h1" >{{ t("shfyActiveUserErrorH1") }}</h1>
      <p data-test-id="activate-account-active-user-error-p">{{ t("shfyActiveUserErrorParagraph") }}</p>
    </div>
    <div data-test-id="activate-account-status-failure"  *ngIf="!(loading$ | async) && isDuplicateError" class="status status-failure">
      <h1 data-test-id="activate-account-duplicate-fiscal-error" >{{ t("shfyDuplicateFiscalCodeErrorH1") }}</h1>
      <p data-test-id="activate-account-duplicate-fiscal-error-p" [innerHTML]="(error$ | async) || t('errorParagraph')"></p>
    </div>
  </ng-template>
</div>
