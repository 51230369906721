import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SelfSamplingInstructionComponent } from "./self-sampling-instruction.component";
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SelfSamplingInstructionComponent],
  exports: [SelfSamplingInstructionComponent],
})
export class SelfSamplingInstructionModule {}
