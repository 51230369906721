import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SendForgotPasswordComponent } from "./send-forgot-password.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { DxpBackgroundModule } from "src/app/modules/shared/dxp-background/dxp-background.module";
import { DxpSendForgotPasswordComponent } from "../dxp-send-forgot-password/dxp-send-forgot-password.component";

@NgModule({
  declarations: [SendForgotPasswordComponent, DxpSendForgotPasswordComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, DxpBackgroundModule],
})
export class SendForgotPasswordModule {}
