<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet" />

<div data-test-id="self-sampling-instruction-container"  class="container">
  <div data-test-id="self-sampling-instruction-navbar-brand"  class="row navbar-brand">
    <div data-test-id="self-sampling-instruction-logo-wrapper"  class="col-12">
      <img data-test-id="self-sampling-instruction-logo"  src="assets/img/synlab-logo.svg" alt="Synlab's logo" class="pl-logo-1 mt-4 mb-2" />
    </div>
  </div>
</div>
<div data-test-id="self-sampling-instruction-container"  class="container body text-synlab mt-3">
  <div data-test-id="self-sampling-instruction-row"  class="row">
    <div data-test-id="self-sampling-instruction-row-column"  class="col-12">
      <h2 data-test-id="self-sampling-instruction-covid-19" >COVID-19</h2>
      <h3 data-test-id="self-sampling-instruction-covid-19-message" >Anleitung für den Lolli-PCR-Pool</h3>
    </div>
  </div>
  <div data-test-id="self-sampling-instruction-row"  class="row">
    <div data-test-id="self-sampling-instruction-row-column"  class="col-12">
      <h4 data-test-id="self-sampling-instruction-row-column-message"  class="font-weight-bold">Sie erhalten folgende Materialien:</h4>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/header-image-1.PNG"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction"
        />
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/header-image-2.PNG"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction"
        />
      </div>
    </div>
  </div>
  <div data-test-id="self-sampling-instruction-border-bottom"  class="row border-bottom border-primary mt-3 pb-3">
    <div data-test-id="self-sampling-instruction-row-column"  class="col-12">
      <h5 data-test-id="self-sampling-instruction-row-column-message"  class="font-weight-bold">
        Die Durchführung der Probenentnahme sollte strikt nach Poolgruppen getrennt erfolgen, um Irrtümer zu vermeiden!
        Empfehlenswert sind maximal 15 Schüler : innen pro Poolgruppe.
      </h5>
    </div>
  </div>
  <div data-test-id="self-sampling-instruction-content-wrapper"  class="row mt-3 mb-3">
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/mobile-app-image-1.PNG"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction"
        />
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-column" class="col-md-4 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-column-message">
        Ihre Schulverwaltung hat Sie im SYNLAB-Schulportal registriert. Klicken Sie bitte in der Ihnen zugesandten
        E-Mail auf den Link und legen Sie dort Ihr persönliches Kennwort für den Zugang an.
      </p>
      <p data-test-id="self-sampling-instruction-column-message" class="font-weight-bold">
        Bitte laden Sie die SYNLAB Access-App herunter und melden sich mit Ihren Zugangsdaten an.
      </p>
      <p data-test-id="self-sampling-instruction-column-message">
        Die App gibt die notwendigen Schritte vor. Legen Sie zunächst je Gruppe in Ihrer Klasse eine Poolgruppe an.
        Geben Sie die Klasse (z.B. 5a) und Poolgruppe (z.B. A) als Bezeichnung an.
      </p>
      <p data-test-id="self-sampling-instruction-column-message" class="font-weight-bold">
        Die Registrierung und Anlage der Poolgruppen müssen nur einmal durchgeführt werden.
      </p>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/mobile-app-image-2.PNG"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction"
        />
      </div>
    </div>

    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/mobile-app-image-3.PNG"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction"
        />
      </div>
    </div>

    <div data-test-id="self-sampling-instruction-container" class="col-md-8 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-message">
        Bitte händigen Sie allen Schüler:innen einen Druckverschlussbeutel aus, mit Tupfer im Transportröhrchen und
        einer Probentüte sowie einer Anleitung für die Probennahme zu Hause.
      </p>
      <p data-test-id="self-sampling-instruction-message" class="font-weight-bold">
        <u>Hinweis</u>: Die Ausgabe von Set B an die Schüler:innen erfolgt nur einmalig (bis zu einer positiven
        Poolanalyse).
      </p>
    </div>
  </div>

  <div data-test-id="self-sampling-instruction-row"  class="row">
    <div data-test-id="self-sampling-instruction-row-column"  class="col-12">
      <h2 data-test-id="self-sampling-instruction-row-column-h2" >Anleitung für die Probenentnahme</h2>
    </div>
  </div>
  <div data-test-id="self-sampling-instruction-row"  class="row mt-3">
    <div data-test-id="self-sampling-instruction-row-column"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-1.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 1"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">1</h1>
        </div>
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-row-column"  class="col-md-4 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-row-column-message">
        Sie öffnen die App und beginnen via „Pooltestung starten“ mit der Durchführung des Tests. Sie folgen den von der
        App vorgegebenen Schritten.
      </p>
      <p data-test-id="self-sampling-instruction-row-column-message">
        Sie wählen eine Gruppe aus, scannen den Strichcode des Poolröhrchens und beginnen mit der Probenentnahme, wie
        nachstehend beschrieben.
      </p>
      <p data-test-id="self-sampling-instruction-row-column-message">
        Sie beschriften den Aufkleber auf der Probentüte von Set A mit Namen und Anschrift Ihrer Einrichtung, der
        Klasse, dem Datum sowie der Poolgruppe.
      </p>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-2.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 2"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">2</h1>
        </div>
      </div>
    </div>

    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-3.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 3"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">3</h1>
        </div>
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-row-column" class="col-md-4 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-message">
        Sie legen die Probentüte und das geöffnete Poolröhrchen bereit.<br data-test-id="self-sampling-instruction-DATA_TEST_ID_PLACEHOLDER"  />Sie nehmen die ersten 15 Tupfer in
        Folienverpackung und verteilen sie an die Schüler:innen der ersten Poolgruppe.
      </p>
      <p data-test-id="self-sampling-instruction-message">Die Schüler:innen nehmen den verpackten Tupfer und öffnen die Verpackung durch Abziehen der Folie.</p>
      <p data-test-id="self-sampling-instruction-message">
        Der Tupfer wird in den Mund genommen und 30 Sekunden gelutscht: Mit der Zunge hin- und herbewegen.
        <strong data-test-id="self-sampling-instruction-message-strong" >Nicht kauen!</strong>
      </p>
    </div>

    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-4.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 4"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">4</h1>
        </div>
      </div>
    </div>

    <div data-test-id="self-sampling-instruction-row-column"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-5.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 5"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">5</h1>
        </div>
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-row-column"  class="col-md-4 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-message">
        Die Schüler:innen nehmen den Tupfer aus dem Mund, halten ihn direkt unter- und oberhalb der Sollbruchstelle fest
        und brechen ihn in zwei Teile.
      </p>
      <p data-test-id="self-sampling-instruction-message">
        Die Schüler:innen gehen zum Poolröhrchen, werfen den wattierten Teil in das Poolröhrchen. Das verbliebene
        Tupferbruchstück und die Verpackung werden im Mülleimer entsorgt.
      </p>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-6.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 6"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">6</h1>
        </div>
      </div>
    </div>

    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-7.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 5"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">7</h1>
        </div>
      </div>
    </div>
    <div data-test-id="self-sampling-instruction-message-wrapper" class="col-md-4 col-sm-6 col-xs-12">
      <p data-test-id="self-sampling-instruction-message">
        Wenn alle Schüler:innen ihre Tupfer eingeworfen haben, verschließen Sie das Poolröhrchen sorgfältig, stecken es
        in die Probentüte und kleben diese zu.
      </p>
      <p data-test-id="self-sampling-instruction-message">Schließen Sie die Probennahme in der App ab.</p>
      <p data-test-id="self-sampling-instruction-message" class="font-weight-bold">Den Befund für Ihre Poolgruppe(n) erhalten Sie über die SYNLAB Access-App.</p>
    </div>
    <div data-test-id="self-sampling-instruction-card-wrapper"  class="col-md-4 col-sm-6 col-xs-12">
      <div data-test-id="self-sampling-instruction-card"  class="card">
        <img data-test-id="self-sampling-instruction-card-img" 
          src="assets/images/self-sampling-instructions/step-8.png"
          class="card-img img-fluid rounded"
          alt="Self-Sampling instruction number 6"
        />
        <div data-test-id="self-sampling-instruction-card-img-overlay"  class="card-img-overlay">
          <h1 data-test-id="self-sampling-instruction-card-title"  class="card-title text-synlab">8</h1>
        </div>
      </div>
    </div>
  </div>

  <div data-test-id="self-sampling-instruction-row"  class="row border-top border-primary mt-3">
    <div data-test-id="self-sampling-instruction-instruction-footer"  class="col-12 pt-5 instruction-footer">
      <h5 data-test-id="self-sampling-instruction-h5" >
        Besteht Ihre Klasse aus mehr als 15 Schüler:innen, wiederholen Sie den Vorgang bitte für weitere Poolgruppen.
      </h5>
      <p>Übrig gebliebene Materialien geben Sie bitte inklusive Probentüten an die Verwaltung zurück.</p>
    </div>
  </div>
</div>
