<ng-container data-test-id="app-use-new-nav-container"  *ngIf="useNewNav$ | async">
  <syn-synlab-header data-test-id="app-synlab-header"  (onLogout)="signout()" [disableLinks]="forceMFA$ | async"></syn-synlab-header>
</ng-container>
<router-outlet data-test-id="app-router-outlet" ></router-outlet>

<div data-test-id="app-loading-wrapper"  class="app-loading" *ngIf="isLangLoading$ | async">
  <div data-test-id="app-loading"  class="loading">
    <div data-test-id="app-loading-indicator"  class="text-center pt-5 pb-5 loading-indicator">
      <app-spinner data-test-id="app-spinner"  class="app-spinner"></app-spinner>
    </div>
  </div>
</div>
