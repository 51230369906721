<ng-container *transloco="let t; read: 'delete'">
  <ng-container *ngIf="!(useNewNav$ | async) && !(isSHFYInstance$ | async)">
    <app-header data-test-id="delete-account-header"  [showDropdown]="false"></app-header>
  </ng-container>
  <ng-container *ngIf="!(isSHFYInstance$ | async); else shfy">
    <div data-test-id="delete-account-container" class="container py-4">
      <div data-test-id="delete-account-row" class="row">
        <ng-container [ngSwitch]="isValidDeleteAccountLink$ | async">
          <ng-container *ngSwitchCase="true">
            <div data-test-id="delete-account-delete-wrapper"  class="col-sm-8 m-auto">
              <div data-test-id="delete-account-delete-card"  class="card py-4 px-4 delete-card">
                <h5 data-test-id="delete-account-text-synlab-primary"  class="text-synlab-primary">
                  {{ t("deleteTitle") }}
                </h5>
                <p data-test-id="delete-account-text-delete-configm-instruction">{{ t("deleteConfirmInstruction1") }}</p>
                <div data-test-id="delete-account-confirm-btn-wrapper"  class="pt-4 btn-sec">
                  <button data-test-id="delete-account-confirm-btn"  class="btn btn-primary" (click)="delete()">{{ t("confirmButton") }}</button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <ng-container *ngIf="useNewNav$ | async; else oldWarning">
              <div data-test-id="delete-account-syn-container"  class="syn-container">
                <h2 data-test-id="delete-account-invalid-link-text" >{{ t("invalidLinkText") }}</h2>
                <button data-test-id="delete-account-redirect-to-contact-synlab-btn"  (click)="redirectToContactSynlab()">
                  <mat-icon data-test-id="delete-account-arrow-forward-icon" >arrow_forward</mat-icon>
                  {{ "completeProfile.openUefaLink" | transloco }}
                </button>
              </div>
            </ng-container>
            <ng-template #oldWarning>
              <div data-test-id="delete-account-alert-warning"  class="alert alert-warning mt-5 m-auto" role="alert">
                {{ t("invalidLinkText") }}
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div data-test-id="delete-account-spinner-wrapper"  class="col-12 d-flex justify-content-center align-items-center pt-5">
              <span data-test-id="delete-account-spinner-content" >
                <mat-spinner data-test-id="delete-account-spinner"  diameter="40" strokeWidth="3"></mat-spinner>
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #shfy>
    <div data-test-id="delete-account-shfy-wrapper"  class="shfy wrapper">
      <div data-test-id="delete-account-shfy-container"  class="container py-4">
        <div data-test-id="delete-account-shfy-row"  class="row">
          <div data-test-id="delete-account-shfy-delete-wrapper"  *ngIf="isValidDeleteAccountLink$ | async; else invalidLink" class="col-sm-8 m-auto">
            <div data-test-id="delete-account-delete-card"  class="card py-4 px-4 delete-card">
              <h5 data-test-id="delete-account-delete-account-title"  class="text-synlab-primary delete-account-title">
                {{ t("deleteTitle") }}
              </h5>
              <p data-test-id="delete-account-delete-confirmation-instruction-1" class="delete-account-message">
                {{ t("deleteConfirmInstruction1") }}
              </p>
              <div data-test-id="delete-account-delete-btn-wrapper"  class="pt-4 btn-sec">
                <button data-test-id="delete-account-delete-btn"  class="delete-btn" (click)="delete()">{{ t("confirmButton") }}</button>
              </div>
            </div>
          </div>
          <ng-template #invalidLink>
            <div data-test-id="delete-account-alert-warning"  class="alert alert-warning mt-5 m-auto" role="alert">
              {{ t("invalidLinkText") }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
