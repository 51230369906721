import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { ConfigService } from "src/app/services/config/config.service";
import { map, take, tap, withLatestFrom } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { combineLatest, Observable, of } from "rxjs";
import { AppState } from "src/app/@store/reducers";
import { select, Store } from "@ngrx/store";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { ActivatedRoute, Router } from "@angular/router";
import { Countries } from "src/app/models/countries.model";
import { AppSelector } from "src/app/@store/selectors/app.selectors";
import { headerScopeKey, I18nService } from "src/app/services/i18n/i18n.service";
import { formatLocale } from "src/app/plasma-ui-common/utils/format-locale";
import { UserRoleService } from "src/app/plasma-ui-common/services/user-role.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() showDropdown: boolean = false;
  isTara: boolean = false;
  plasmaLink$ = this.config.envConfig$.pipe(
    map((config) => {
      if (this.usertype.isAdminUser() || this.usertype.isCountryAdmin() || this.usertype.isUserAdmin()) {
        return config.BaseAdminUrl;
      } else if (this.usertype.isWebreqAdminUser()) {
        return config.BaseWebreqAdminUrl;
      } else if (this.usertype.isWebreqUser()) {
        return config.BaseWebreqUrl;
      } else if (this.usertype.isUefaUser()) {
        return config.BaseUefaUrl;
      } else if (this.usertype.isPatient() || this.usertype.isAppUser()) {
        return config.BasePlasmaUrl;
      } else if (
        this.usertype.isSchoolAdmin() ||
        this.usertype.isSchoolTeacher() ||
        this.usertype.isLolliSuperAdmin ||
        this.usertype.isCustomerCare()
      ) {
        return config.BasePlasmaUrl;
      } else {
        return "";
      }
    })
  );

  logo$: Observable<string> = this.store.select(StsSelectors.getAppLogo);
  bookingLink$ = this.store.select(StsSelectors.getBaseBookingUrl);
  checkResultLink$ = this.store.select(StsSelectors.getCheckResultUrl);
  bookingLinkEnabled$ = this.store.pipe(select(StsSelectors.getBookingLinkEnabled));
  isSynlabAccessUser$ = this.store.pipe(select(StsSelectors.isSynlabAccessUser));
  isUkDfpAdminUser$ = this.store.pipe(select(StsSelectors.isUkDfpAdminUser));
  isUkShfyAdminUser$ = this.store.pipe(select(StsSelectors.isUkShfyAdminUser));

  availableLangs = this.i18n.getAvailableLanguages().map((lang) => formatLocale(lang));
  countries = Countries.filter((c) => this.availableLangs.indexOf(formatLocale(c.countryCode)) > -1);
  activeLang$ = this.store.pipe(select(AppSelector.getActiveLanguage));

  plasmaLabel$ = this.transloco.langChanges$.pipe(
    withLatestFrom(this.isUkDfpAdminUser$, this.isUkShfyAdminUser$),
    map(([lang, isUkDfpAdminUser, isUkShfyAdminUser]) => {
      if (this.usertype.isCustomerCare() || this.usertype.isDenmarkAdmin()) {
        return `${headerScopeKey}.userLabel`;
      } else if (!this.usertype.isDenmarkAdmin() || this.usertype.isPatient() || this.usertype.isAppUser()) {
        return `${headerScopeKey}.myResultLink`;
      } else {
        return isUkDfpAdminUser || isUkShfyAdminUser
          ? `${headerScopeKey}.userManagementLabel`
          : `${headerScopeKey}.plasmaLabel`;
      }
    })
  );

  forceMFA$ = this.store.pipe(select(StsSelectors.isMFARequired));
  showPreventionWellnessLink$ = of(false);
  preventionWellnessLink$ = this.store.pipe(select(StsSelectors.getPreventionWellnessLink));

  isEEInstance$ = this.store.pipe(select(StsSelectors.isEstoniaInstance));
  isHUTenant$ = this.store.pipe(select(StsSelectors.isHUTenant));
  isSHFYInstance$ = this.store.pipe(select(StsSelectors.isUkShfyInstance));
  isSuperSupportUser$ = this.store.pipe(select(StsSelectors.isSuperSupportUser));

  constructor(
    public auth: AuthService,
    public config: ConfigService,
    private transloco: TranslocoService,
    private store: Store<AppState>,
    private router: Router,
    private i18n: I18nService,
    private activeRoute: ActivatedRoute,
    private usertype: UserRoleService
  ) {}

  defLanguage = this.transloco.getActiveLang();

  ngOnInit() {
    this.isTara = this.activeRoute.firstChild.snapshot.data.isTara;
    this.logo$.subscribe((n) => console.log(n));
  }

  startSignoutMainWindow() {
    this.auth.startSignoutMainWindow();
  }

  navigateLink() {
    combineLatest([this.isEEInstance$, this.isHUTenant$, this.forceMFA$])
      .pipe(
        take(1),
        tap(([isEE, isHU, isMFARequired]) => {
          if (!isMFARequired) {
            if (isEE || isHU) {
              window.location.href = this.config.basePlasmaUrl;
            } else {
              this.router.navigate(["/account/profile"]);
            }
          }
        })
      )
      .subscribe();
  }

  get initial(): Observable<string> {
    return this.store.pipe(select(StsSelectors.getUserInfo)).pipe(
      map((claims) => {
        if (!claims) return "U";

        const family_name = claims.family_name?.trim();
        const given_name = claims.given_name?.trim();
        const email = claims.email;

        if (family_name && given_name) {
          return given_name?.charAt(0) + family_name?.charAt(0);
        } else if (family_name) {
          return family_name.substring(0, 2);
        } else if (given_name) {
          return given_name.substring(0, 2);
        } else {
          return email.substring(0, 2);
        }
      })
    );
  }

  /**
   * Change the active language
   * @param language string. two-letter country code.
   */
  changeActiveLanguage(language: string) {
    this.i18n.setStoredLanguage(language.toLowerCase());
    this.updateQueryParam(language);
  }

  private updateQueryParam(locale) {
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: { langCode: locale },
      queryParamsHandling: "merge",
    });
  }
}
