import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { Profile } from '../core/profile/profile.service';
import { LocalePickerModule } from '../locale-picker/locale-picker.component';
import { Link } from '../model/link.model';
import { MIN_DESKTOP_WIDTH } from '../core/constants';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy {
  @Input() links: Link[] = [];
  @Input() brandLogo = '/assets/img/synlab-logo.svg';
  @Input() myAccountLink:string;
  @Input() brandLogoLink: string;
  @Input() disableLinks = false;
  @Input() user?: Profile;

  @Output() onLogout = new EventEmitter();
  @Output() onMyAccount = new EventEmitter();

  isMobile$ = new BehaviorSubject<boolean>(document.documentElement.clientWidth  < MIN_DESKTOP_WIDTH);
  isMobileMenuOpen$ = new BehaviorSubject<boolean>(false);
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _router:Router) {
    _router.events
      .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.isMobileMenuOpen$.next(false) )
  }

  resize$ = fromEvent(window, 'resize')
    .pipe(takeUntil(this.destroy$), auditTime(200))
      .subscribe(() => {
    this.isMobile$.next(document.documentElement.clientWidth  < MIN_DESKTOP_WIDTH);
  });

  toggleMenu() {
    this.isMobileMenuOpen$.next(!this.isMobileMenuOpen$.getValue());
  }

  logout() {
    this.onLogout.emit()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public get getDisplayedLinks(): Link[] {
    return this.links.filter(v => v?.url)
  }

}

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule, MatButtonModule, MatMenuModule, MatIconModule, LocalePickerModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
