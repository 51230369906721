import { createAction, props } from "@ngrx/store";
import { LegalDocumentVersionInterface } from "src/app/interfaces/legal-document-version.interface";
import { LegalDocumentInterface } from "src/app/interfaces/legal-document.interface";

export const searchLegalDocumentsAction = createAction(
  "[Legal Documents] search legal documents",
  props<{ userId: string }>()
);
export const searchLegalDocumentsSuccessAction = createAction(
  "[Legal Documents] search legal documents success",
  props<{ legalDocuments: LegalDocumentInterface[] }>()
);
export const searchLegalDocumentsVersionsAction = createAction(
  "[Legal Documents] search legal documents version",
  props<{ userId: string; docType: string }>()
);
export const searchLegalDocumentsVersionsSuccessAction = createAction(
  "[Legal Documents] search legal documents version success",
  props<{ versions: LegalDocumentVersionInterface[] }>()
);
