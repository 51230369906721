import { createAction, props } from "@ngrx/store";
import { ELoginMethod } from "src/app/appsettings";
import { CountryInfo } from "src/app/services/country/country.service";
import { UserInfo } from "../reducers/sts.reducer";

const loadStss = createAction("[Sts] Load Stss");

export interface Claim {
  claimType: string;
  claimValue: string;
}

export interface IDecodedToken {
  synlabId: string;
  role: string[];
  name: string;
  country: string;
  email: string;
  token: string;
  idp: ELoginMethod;
}

const loadStssSuccess = createAction("[Sts] Load Stss Success", props<{ data: IDecodedToken }>());
const loadStssFailure = createAction("[Sts] Load Stss Failure", props<{ error: any }>());
const loadConfigSuccess = createAction("[Sts] Load Config Success", props<{ data: any }>());

const addUserInfo = createAction("[Sts] Add User Info", props<{ data: UserInfo }>());

const checkLoginStatus = createAction("[Sts] Check Login Status", props<{ value: boolean }>());

const loadProfileSuccess = createAction("[STS] Load Profile Success", props<{ claims: Claim[] }>());

const loadProfileFailed = createAction("[STS] Load Profile Failed", props<{ error: string | any }>());

/**
 * STS Country actions
 */
const loadCountry = createAction("[STS] Load country", props<{ code: string }>());
const loadCountryFailed = createAction("[STS] Load country failed");
const loadCountrySuccess = createAction("[STS] Load country success", props<{ payload: CountryInfo }>());

/**
 * STS claim actions
 */
const setClaims = createAction("[STS] Set claims", props<{ claims: Claim[] }>());
const setProfileEmail = createAction("[STS] Set profile email address", props<{ email: string }>());

const setHasGeneplanetOrders = createAction("[STS] Set Has Geneplanet Orders", props<{ hasOrders: boolean | null }>());
const fetchHasGeneplanetOrders = createAction("[STS] Fetch Has Geneplanet Orders ");
const setGeneplanetEnrolled = createAction("[STS] Set Geneplanet Enrolled", props<{ isEnrolled: boolean | null }>());

export const STSActions = {
  setProfileEmail,
  loadProfileSuccess,
  loadProfileFailed,
  checkLoginStatus,
  addUserInfo,
  loadConfigSuccess,
  loadStss,
  loadStssSuccess,
  loadStssFailure,
  loadCountry,
  loadCountrySuccess,
  loadCountryFailed,
  setClaims,
  setHasGeneplanetOrders,
  fetchHasGeneplanetOrders,
  setGeneplanetEnrolled,
};
