import { Injectable } from "@angular/core";
import * as libphonenumber from "google-libphonenumber";

export interface PhoneNumber {
  e164Number: string;
  countryCode: string;
  number: number;
  internationalNumber: string;
  nationalNumber: string;
  dialCode: string;
}

@Injectable({
  providedIn: "root",
})
export class PhoneValidationService {
  isValidNumber(inputNumber: PhoneNumber) {
    if (inputNumber) {
      // special override for Estonia to be able to accept sample numbers in https://github.com/SK-EID/MID/wiki/Test-number-for-automated-testing-in-DEMO
      if (inputNumber.countryCode == "EE" && String(inputNumber.number).length == 8) {
        return true;
      } else {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        let valid = false;
        try {
          valid = phoneUtil.isValidNumber(phoneUtil.parse(inputNumber.e164Number));
        } catch {}
        return valid;
      }
    }
    return false;
  }
}
