import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import * as _ from "lodash";

/** moment's date format string for full date with day of week. */
const momentFullDateFormat = "dddd, MMMM Do YYYY";

@Injectable({
  providedIn: "root",
})
export class CustomNgbDatepickeri18nService extends NgbDatepickerI18n {
  getWeekdayLabel(weekday: number) {
    return this.getWeekdayShortName(weekday);
  }
  getWeekdayShortName(weekday: number): string {
    const momentWeekdayIndex = weekday % 7;
    return moment.weekdaysShort()[momentWeekdayIndex];
  }
  getMonthShortName(month: number, year?: number): string {
    const momentMonthIndex = (month - 1) % 12;
    return moment.monthsShort()[momentMonthIndex];
  }
  getMonthFullName(month: number, year?: number): string {
    const momentMonthIndex = (month - 1) % 12;
    return moment.months()[momentMonthIndex];
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    const mom = moment(jsDate);
    return mom.format(momentFullDateFormat);
  }
  getDayNumerals(date: NgbDateStruct): string {
    return _.toString(date.day);
  }
  getWeekNumerals(weekNumber: number): string {
    return _.toString(weekNumber);
  }
  getYearNumerals(year: number): string {
    return _.toString(year);
  }
}
